//
// React.
//
import PropTypes from 'prop-types'
//
// Material UI.
//
import { Box, IconButton, Stack, Tooltip, Typography } from '@mui/material'
import { ArrowBack, KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material'
//
// Search.
//
import Constants from '../../../config/Constants'
import Filter from '../../../actions/Filter'
import Labels from '../../../config/Labels'
import Util from '../../../services/Util'
//
// CSS.
//
import './TreeListFilter.css'
//
// A filter for navigating categories with multiple segments (levels).
//
class TreeListFilter extends Filter {
    //
    // Construct a new instance.
    //
    constructor(props) {
        super(props)

        this.state = {
            //
            // Values from the search API consist of segments, separated by a separator character, e.g.,
            // "STM32-BIT Arm Cortex MCUs¤STM32 High Performance MCUs". The prefix is either empty or has
            // has one (or more) segments, separated by and ending with the separator character, e.g.
            // "STM32-BIT Arm Cortex MCUs¤".
            //
            valuePrefix: null,
            displayedEntries: this.props?.config?.filterOptions || 10,
        }
    }
    //
    // Function to load more entries
    //
    loadMoreEntries = (totalFilters) => {
        this.setState({
          displayedEntries: totalFilters || 10,
        });
    };
    //
    // Function to Show less entries
    //
    showLessEntries = () => {
        this.setState({
            displayedEntries: this.props?.config?.filterOptions || 10,
        });
    };
    /**
     * Check the search parameters for a value matching the filter name.
     *
     * @returns the filter value from the search parameters.
     */
    getFilterParameterValue = () => {
        const parameterValues = (this.props.searchParameters && this.props.searchParameters[this.props.name]) || []
        return (parameterValues.length > 0) ? parameterValues[0] : ''
    }
    //
    // Render the component.
    //
    render = () => {
        //
        // Get the filter configuration.
        //
        const filterName = this.props.name
        const filter = this.getConfiguredFilter(filterName)
        //
        // Do not continue, if there's nothing to show.
        //
        const filterValues = this.getValues(filterName)
        if (filterValues.length === 0) {
            return null
        }
         //
        // Check active checkbox list filters. When loading the page the cache is empty
        // and not all list entries will be available. Use the refire-approach to load
        // all results to get the list entries and then get the filtered search results.
        //
        const processCheckboxFilters = (searchParameters) => {
            const checkboxFiltersToApply = {};
            const updatedSearchParameters = { ...searchParameters };
            Object.keys(updatedSearchParameters)
                .filter(key => this.isCheckBoxListFilter(key) && updatedSearchParameters[key].length > 0)
                .forEach(filterName => {
                    checkboxFiltersToApply[filterName] = updatedSearchParameters[filterName];
                    delete updatedSearchParameters[filterName];
                });
            //
            // Repeating search becomes necessary if list entries need to
            // be retrieved or all filters need to be preserved.
            //
            const applycheckboxFilters = (Object.keys(checkboxFiltersToApply).length > 0);
            return { checkboxFiltersToApply, updatedSearchParameters, applycheckboxFilters };
        }
        //
        // Create the labels from the search results, omitting empty and null categories. The labels
        // are used for display, omitting empty segments. The corresponding values need to be sent
        // fully (including empty segments) so that the Lucidworks query can be built properly, i.e.,
        // querying each tree segment separately.
        //
        filterValues.forEach(filterValue => {
            filterValue.label = filterValue.value
                .split(this.separator)
                .filter(value => (value && (value !== 'null')))
                .join(this.separator)
        })
        //
        // Derive the current prefix and value from the search parameters. Also remove empty segments
        // from the label prefix.
        //
        const filterParameterValue = this.getFilterParameterValue()
        const valuePrefix = this.state.valuePrefix || filterParameterValue || ''
        const labelPrefix = valuePrefix.replaceAll(new RegExp(`${this.separator}+`, 'g'), this.separator)
        //
        // Identify the initial (parent) category if a prefix is set.
        //
        const labelPrefixSegments = labelPrefix.split(this.separator)
        const parentCategory = (labelPrefixSegments.length > 1) ? labelPrefixSegments[labelPrefixSegments.length - 2] : null
        //
        // Get the categories for the current prefix.
        //
        const listEntries = filterValues
            .filter(filterValue => filterValue.label.startsWith(labelPrefix))
            .reduce((categories, filterValue) => {
                //
                // Get the list entry: the category value starting after the prefix
                // until the next separator.
                //
                const subCategorySegments = filterValue.label.substring(labelPrefix.length).split(this.separator)
                const listEntry = subCategorySegments[0]
                //
                // If there is no category for the list entry yet, create one.
                //
                // Note: The tree is built using a facet holding the complete path to each document,
                // so that "in-memory" navigation is possible. However, a document may have more than
                // one path, so computing the numbers based on the counts of each path is wrong,
                // since a single document may be counted multiple times. Thus, the counts are taken
                // from a tree-level-specific facet, e.g., productTreeLevel1_ss, so that they are
                // accurate.
                //
                if (!categories[listEntry]) {
                    //
                    // Determine the CURRENT category level.
                    //
                    // Use the label prefix to build the value prefix with a regular expression:
                    // 1. Allow multiple occurences of the separator (empty categories): '¤' -> '¤+'
                    // 2. Allow ONE child category: Append '.*?¤'
                    //
                    // Get the value prefix using the regular expression and count the number of
                    // separators (i.e., the number of the current level).
                    //
                    let categoryValueExpression = labelPrefix.replaceAll(new RegExp(this.separator, 'g'), `${this.separator}+`)
                    categoryValueExpression = `^${categoryValueExpression}.*?${this.separator}`
                    const categoryValue = filterValue.value.match(new RegExp(categoryValueExpression, 'g'))
                    const categoryLevel = (categoryValue)
                        ? (categoryValue[0].match(new RegExp(this.separator, 'g')) || []).length
                        : 1
                    //
                    // Get the name of the current level filter, e.g., productTree_ss -> productTreeLevel2_ss .
                    // Then search the list entry in the current level filter to get the number of documents.
                    //
                    const currentLevelFilterName = filterName.replaceAll(/_(.*?)$/g, `Level${categoryLevel}_$1`)
                    const currentLevelCategory = this.getValues(currentLevelFilterName).find(category => (category.value === listEntry))
                    //
                    // Create the new category for this list entry.
                    //
                    categories[listEntry] = {
                        ...filterValue,
                        hasChildren: (subCategorySegments.length > 1),
                        count: currentLevelCategory?.count || filterValue.count || 0,
                    }
                } else {
                    //
                    // If the category already exists, it may not know that it has children (in
                    // case it was created with the top-level list entry which does not have any
                    // children). Enable the hasChildren flag, if it is a non top-level list entry.
                    //
                    categories[listEntry].hasChildren |= (subCategorySegments.length > 1)
                }
                return categories
            }, {})
        //
        // Sort the list entries by count in descending order.
        // https://stackoverflow.com/questions/1069666/sorting-object-property-by-values
        //
        const sortedKeys = Object.entries(listEntries).sort(([, listEntry1], [, listEntry2]) => listEntry2.count - listEntry1.count).reduce((listEntries, [key, ]) => {
            listEntries.push(key)
            return listEntries
        }, [])
        //
        // Get the subheader value based on the filter value. If the value ends with the
        // separator (i.e. there are children), the last segment will be empty and needs
        // to be removed. The subheader value will be the last segment.
        //
        const filterValueSegments = filterParameterValue.split(this.separator).filter(segment => segment !== '')
        const subheaderValue = filterValueSegments[filterValueSegments.length - 1]
        //
        // Decide whether to show the options. Show the options, if no value is selected
        // yet or the option has children to choose from.
        //
        const showOptions = (!filterParameterValue || filterParameterValue.endsWith(this.separator))
        //
        // Construct the filter header consisting of its name, an option to delete the
        // current filter, or the name of the parent category if navigating the tree.
        //
        var filterHeader = <Stack className='stackSpace' direction='row' alignItems='center'><Typography variant='overline'>{ this.getLabel(filter.label) }</Typography></Stack>
        if (filterParameterValue) {
            if (filterParameterValue.startsWith(valuePrefix)) {
                //
                // Show the delete button if a filter value has been selected and the
                // navigation is not inside a sub-category.
                //
                filterHeader = <Stack className='stackSpace' direction='row' alignItems='center'>
                    <IconButton
                        size='small'
                        onClick={
                            (event) => {
                                //
                                // Get the previous Level.
                                //
                                const adjustedFilterParameterValue = filterParameterValue.endsWith(this.separator) ? filterParameterValue : `${filterParameterValue}${this.separator}`;
                                const labelPrefixSegments = adjustedFilterParameterValue.split(this.separator);
                                const adjustedLabelPrefixSegments = labelPrefixSegments.filter((e, i) => e || (i > 0 && labelPrefixSegments[i - 1] !== ""));
                                const updatedPrefix = adjustedLabelPrefixSegments.slice(0, Math.max(0, adjustedLabelPrefixSegments.length - 2)).join(this.separator)
                                const valuePrefixPL = updatedPrefix ? `${updatedPrefix}${this.separator}` : ''
                                this.props.setCacheNoResultState('')
                                //
                                // Call loading Page
                                //
                                this.props.setLoadingState(true);
                                //
                                // Delete the filter value and start the search.
                                //
                                const updatedSearchParameters = {
                                    ...this.props.searchParameters,
                                    [filterName]: valuePrefixPL ? [valuePrefixPL] : [],
                                }
                                updatedSearchParameters[this.getPageParameter()] = 1
                                //
                                // If the preservartion filters is active then use the normal behavior.
                                //
                                if(this.props.config?.searchResults?.keepLastSelectionOnly || this.props.config?.searchResults?.preserveFilters){
                                    this.search(updatedSearchParameters, false)
                                    //
                                    // Send a signal.
                                    //
                                    this.sendSignal(Constants.signal.filterRemoved, updatedSearchParameters, [{
                                        name: filterName,
                                        values: [ filterParameterValue ],
                                    }])
                                } else {
                                    //
                                    // Call the function to process applied checkboxfilters
                                    //
                                    const { checkboxFiltersToApply, updatedSearchParameters: finalSearchParameters, applycheckboxFilters } = processCheckboxFilters(updatedSearchParameters);
                                    //
                                    // If needed, cache checkbox list filter entries, repeat the search,
                                    // and store the search results.
                                    //
                                    const filters = this.getConfiguredFilters()
                                    var filterParameters = Object.keys(finalSearchParameters).filter(parameter => Object.keys(filters).includes(parameter))
                                    const activeFilters = filterParameters.filter(filterName => (finalSearchParameters[filterName] && finalSearchParameters[filterName].length > 0))
                                    const clearFilters = (activeFilters.length === 0)
                                    if (applycheckboxFilters) {
                                        this.search(finalSearchParameters, clearFilters, applycheckboxFilters, checkboxFiltersToApply)
                                    }else {
                                        this.search(finalSearchParameters, clearFilters)
                                    }
                                    //
                                    // Send a signal.
                                    //
                                    this.sendSignal(Constants.signal.filterRemoved, finalSearchParameters, [{
                                        name: filterName,
                                        values: [ filterParameterValue ],
                                    }])
                                }
                            }
                        }
                    ><Tooltip title={this.getLabel(Labels.TreeListFilter.PreviousLevel)}><ArrowBack /></Tooltip></IconButton>
                    <Typography variant='overline'>{ subheaderValue }</Typography>
                </Stack>
            } else {
                //
                // Although a filter value has been selected, the current selection is
                // inside a sub-category and an option to go back needs to be shown.
                //
                filterHeader = <Stack className='stackSpace' direction='row' alignItems='center'>
                    <IconButton
                        size='small'
                        onClick={
                            (event) => {
                                //
                                // Change the prefix to display the previous level.
                                //
                                const updatedPrefix = labelPrefixSegments.slice(0, Math.max(0, labelPrefixSegments.length - 2)).join(this.separator)
                                this.setState({
                                    valuePrefix: updatedPrefix ? `${updatedPrefix}${this.separator}` : ''
                                })
                            }
                        }
                    ><Tooltip title={this.getLabel(Labels.TreeListFilter.PreviousLevel)}><KeyboardArrowLeft /></Tooltip></IconButton><Typography variant='overline'>{ labelPrefixSegments[labelPrefixSegments.length - 2] }</Typography>
                </Stack>
            }
        } else if (parentCategory) {
            //
            // There's no filter value selected, but the current selection is inside a 
            // sub-category: Show option to go back up.
            //
            filterHeader = <Stack className='stackSpace' direction='row' alignItems='center'>
                <IconButton
                    size='small'
                    onClick={
                        (event) => {
                            //
                            // Change the prefix to display the previous level.
                            //
                            const updatedPrefix = labelPrefixSegments.slice(0, Math.max(0, labelPrefixSegments.length - 2)).join(this.separator)
                            this.setState({
                                valuePrefix: updatedPrefix ? `${updatedPrefix}${this.separator}` : ''
                            })
                        }
                    }
                ><Tooltip title={this.getLabel(Labels.TreeListFilter.PreviousLevel)}><KeyboardArrowLeft /></Tooltip></IconButton>
                <span
                    className='ListHeader'
                    onClick={
                        (event) => {
                            this.props.setCacheNoResultState('')
                            //
                            // Activate loading page.
                            //
                            this.props.setLoadingState(true);
                            //
                            // Use the parent category to update the search parameters (must be an array).
                            //
                            const updatedSearchParameters = {
                                ...this.props.searchParameters,
                                [filterName]: [ labelPrefix ],
                            }
                            updatedSearchParameters[this.getPageParameter()] = 1
                            //
                            // If the preservartion filters is active then use the normal behavior.
                            //
                            if(this.props.config?.searchResults?.keepLastSelectionOnly || this.props.config?.searchResults?.preserveFilters){
                                this.search(updatedSearchParameters)
                                //
                                // Send a signal.
                                //
                                this.sendSignal(Constants.signal.filterAdded, updatedSearchParameters, [{
                                    name: filterName,
                                    values: [ labelPrefix ],
                                }])
                            } else {
                                //
                                // Call the function to process applied checkboxfilters
                                //
                                const { checkboxFiltersToApply, updatedSearchParameters: finalSearchParameters, applycheckboxFilters } = processCheckboxFilters(updatedSearchParameters);
                                //
                                // If needed, cache checkbox list filter entries, repeat the search,
                                // and store the search results.
                                //
                                if (applycheckboxFilters) {
                                    this.search(finalSearchParameters, false, applycheckboxFilters, checkboxFiltersToApply)
                                }else {
                                    this.search(finalSearchParameters, false)
                                }
                                //
                                // Send a signal.
                                //
                                this.sendSignal(Constants.signal.filterAdded, finalSearchParameters, [{
                                    name: filterName,
                                    values: [ labelPrefix ],
                                }])
                            }
                        }
                    }
                ><Typography variant='overline'>{ parentCategory }</Typography></span>
            </Stack>
        }
        //
        // Filter the activeFilterValues to display only the desired number of entries
        //
        const entriesToDisplay = sortedKeys.slice(0, this.state.displayedEntries);
        //
        // Render the tree list filter.
        //
        const numberFormat = new Intl.NumberFormat(Constants.locale[this.getLanguage()])
        //
        // Determine whether a didyoumean is received
        //
        // const isDidYouMean = this.props.searchParameters[Constants.parameter.didYouMean];
        return (
            <div className='TreeListFilter'>
                <Box sx={{
                    // borderTop: isDidYouMean ? 0 : 1,
                    borderTop: 1,
                    borderTopColor: '#dbdee2',
                    // borderBottom: isDidYouMean ? 0 : 1,
                    borderBottom: 1,
                    borderBottomColor: '#dbdee2',
                    // borderLeft: isDidYouMean ? 0 : 4,
                    borderLeft: 4,
                    borderLeftColor: filter.display.borderColor,
                    paddingLeft: '0.5rem',
                    mb: 2,
                 }}
                >
                    { filterHeader }
                    {
                        showOptions && entriesToDisplay.map(key => {
                            const listEntry = listEntries[key]
                            if (key) {
                                return (
                                    <Stack
                                        key={key}
                                        sx={{ height: 'auto' }}
                                        direction='row'
                                        alignItems='center'
                                        justifyContent='space-between'
                                        className='TreeListEntry'
                                    >
                                        <span
                                            className='ListEntry'
                                            onClick={
                                                (event) => {
                                                    this.props.setCacheNoResultState('')
                                                    //
                                                    // If the current list entry has children, search for the current tree node 
                                                    // (consisting of the value prefix based on label prefix, i.e., including all
                                                    // empty segements) and all its children, otherwise search for the full entry.
                                                    // The value prefix will be repeating the separator more than once, thus it
                                                    // can be easily extracted with a regex based on the label prefix, allowing for
                                                    // multiple separators at the end instead of only one.
                                                    //
                                                    // Amplifiers and Comparators¤+ : Allow multiple separators turning '¤' to '¤+'
                                                    const prefixRegex = Util.escapeRegExp(labelPrefix).replaceAll(new RegExp(`(${this.separator})`, 'g'), '$1+')
                                                    // ^(Amplifiers and Comparators¤+)
                                                    const valuePrefixWithEmptyChildren = labelPrefix ? (listEntry.value.match(new RegExp(`^(${prefixRegex})`, 'g')) || []).join() : ''
                                                    const updatedFilterValue = listEntry.hasChildren
                                                        ? `${valuePrefixWithEmptyChildren}${key}${this.separator}`
                                                        : listEntry.value
                                                    //
                                                    // Activate loading page.
                                                    //
                                                    this.props.setLoadingState(true);
                                                    //
                                                    // Use the filter value to update the search parameters (must be an array)
                                                    // and run the search.
                                                    //
                                                    const updatedSearchParameters = this.getUpdatedSearchParameters(filterName, [ updatedFilterValue ])
                                                    //
                                                    // If the preservartion filters is active then use the normal behavior.
                                                    //
                                                    if(this.props.config?.searchResults?.keepLastSelectionOnly || this.props.config?.searchResults?.preserveFilters){
                                                        this.search(updatedSearchParameters)
                                                        //
                                                        // Send a signal.
                                                        //
                                                        this.sendSignal(Constants.signal.filterAdded, updatedSearchParameters, [{
                                                            name: filterName,
                                                            values: [ updatedFilterValue ],
                                                        }])
                                                    } else {
                                                        //
                                                        // Call the function to process applied checkboxfilters
                                                        //
                                                        const { checkboxFiltersToApply, updatedSearchParameters: finalSearchParameters, applycheckboxFilters } = processCheckboxFilters(updatedSearchParameters);
                                                        //
                                                        // If needed, cache checkbox list filter entries, repeat the search,
                                                        // and store the search results.
                                                        //
                                                        if (applycheckboxFilters) {
                                                            this.search(finalSearchParameters, false, applycheckboxFilters, checkboxFiltersToApply)
                                                        }else {
                                                            this.search(finalSearchParameters, false)
                                                        }
                                                        //
                                                        // Send a signal.
                                                        //
                                                        this.sendSignal(Constants.signal.filterAdded, finalSearchParameters, [{
                                                            name: filterName,
                                                            values: [ updatedFilterValue ],
                                                        }])
                                                    }
                                                }
                                            }
                                        >
                                            <Tooltip title={key} placement="top">
                                                <span className="Truncate">
                                                    <Typography>{`${key} (${numberFormat.format(listEntry.count)})`}</Typography>
                                                </span>
                                            </Tooltip>
                                        </span>
                                        {
                                            listEntry.hasChildren && <IconButton
                                                size='small'
                                                onClick={
                                                    (event) => {
                                                        //
                                                        // Change the prefix to display the next level.
                                                        //
                                                        this.setState({
                                                            valuePrefix: `${valuePrefix}${key}${this.separator}`
                                                        })
                                                    }
                                                }
                                            ><Tooltip title={this.getLabel(Labels.TreeListFilter.NextLevel)}><KeyboardArrowRight color="arrows"/></Tooltip></IconButton>
                                        }
                                    </Stack>
                                )
                            } else {
                                return (<div key={`${listEntry.value}-${listEntry.count}`}></div>)
                            }
                        })
                    }
                    {this.state.displayedEntries < sortedKeys.length && (
                        <Typography variant='body1' sx={{ mt: 1, mb: 1 }}>
                            <span
                                className='Link'
                                onClick={() => this.loadMoreEntries(sortedKeys.length)}
                            >{this.getLabel(filter?.showMoreLabel || 'No label')}</span>
                        </Typography>
                    )}
                    {( (this.state.displayedEntries === sortedKeys.length) && (entriesToDisplay.length > this.props?.config?.filterOptions)) && (
                        <Typography variant='body1' sx={{ mt: 1, mb: 1 }}>
                            <span
                                className='Link'
                                onClick={this.showLessEntries}
                            >{this.getLabel(filter?.showFewerLabel || 'No label')}</span>
                        </Typography>
                    )}
                </Box>
            </div>
        )
    }
}

TreeListFilter.propTypes = {
    name: PropTypes.string.isRequired,
    config: PropTypes.object.isRequired,
    searchResult: PropTypes.object.isRequired,
    searchParameters: PropTypes.object.isRequired,
    separator: PropTypes.string,
    setFilterResult: PropTypes.func.isRequired,
    setSearchParameters: PropTypes.func.isRequired,
    setSearchResult: PropTypes.func.isRequired,
}

export default TreeListFilter