//
// React.
//
import { useEffect, useState } from 'react'
// 04.09.2024: Disabled due to issues during first go-live.
// import Cookies from 'js-cookie';
//
// Search components.
//
import Constants from './config/Constants'
import ErrorMessage from './components/ErrorMessage/ErrorMessage'
import Footer from './components/Footer/Footer'
import Labels from './config/Labels'
import Loading from './components/Loading/Loading'
import SearchBox from './components/SearchBox/SearchBox'
import SearchResultsPanel from './components/SearchResultsPanel/SearchResultsPanel'
import Util from './services/Util'
import Search from './actions/Search'
import Sidebar from './components/SearchResults/Sidebar/Sidebar'
//
// Import Custom Theme
//
import { ThemeProvider } from '@mui/material';
import CustomTheme from './theme/CustomTheme';

function App() {
    //
    // State variables.
    //
    const [cachedFilters, setCachedFilters] = useState({})
    const [cacheNoResult, setCacheNoResult] = useState('')
    const [canSearch, setCanSearch] = useState(false)
    const [config, setConfig] = useState()
    const [docFilterLoading, setDocFilterLoading] = useState({})
    const [errorMessage, setErrorMessage] = useState('')
    const [filterResult, setFilterResult] = useState({})
    const [footerJson, setFooterJson] = useState(null)
    const [footerNewsletterHTML, setFooterNewsletterHTML] = useState(null)
    const [loading, setLoading] = useState(false)
    const [noResult, setNoResult] = useState(false)
    const [resultXref, setResultXref] = useState(0)
    const [searchParameters, setSearchParameters] = useState({})
    const [searchResult, setSearchResult] = useState({})
    const [stateActiveSidebar, setStateActiveSidebar] = useState(false)
    const [statePrevTotalResult, setStatePrevTotalResult] = useState(0)
    const [previousFacets, setpreviousFacets] = useState({})
    const [isFirstClick, setIsFirstClick] = useState("yes")
    const [isFirstClickXRef, setIsFirstClickXRef] = useState("yes")

    const search = new Search({searchParameters, searchResult, setSearchParameters, setSearchResult, config, setCachedFilters, setFilterResult, setpreviousFacets})
    //
    // Apply search parameters supplied in the browser address.
    //
    const applySearchParameters = (location, activeConfig) => {
        const resultSources = activeConfig.sources?.values || []
        //
        // If search parameters are supplied in the browser address, add the parameters
        // to an object which is then used to update the search result.
        //
        const locationSearchParameters = {}
        if (location?.search) {
            //
            // Compute a set of positive integer parameters based on the result sources
            // found in the active configuration.
            //
            const positiveIntParameters = resultSources.map(resultSource => Util.getPageParameter(resultSource))
            //
            // Process all key/value pairs found in the location's search portion and
            // validate their values.
            //
            for (var [key, value] of new URLSearchParams(location.search).entries()) {
                //
                // Ignore empty values, then keep number, plain (scalar) string, or 
                // convert to string array depending on parameter name.
                //
                if (value?.length > 0) {
                    //
                    // Check for JSON encoded parameter values (this should be the norm).
                    //
                    try {
                        value = JSON.parse(value)
                    } catch (error) {
                        // ignore.
                    }
                    //
                    //  Treat different types of values individually.
                    //
                    if (positiveIntParameters.includes(key)) {
                        //
                        // Make sure the value is a positive integer, otherwise use 1 as default.
                        //
                        const intValue = parseInt(value)
                        locationSearchParameters[key] = (isNaN(intValue) || (value < 1)) ? 1 : intValue
                    } else if (Constants.scalarParameters.includes(key)) {
                        //
                        // Validate the values agains valid values (the value itself, if no
                        // valid values have been defined).
                        //
                        const validParameterValues = activeConfig.validParameterValues[key] || { values: [], defaultValue: value }
                        locationSearchParameters[key] = validParameterValues.values.includes(value) ? value : validParameterValues.defaultValue
                    } else {
                        //
                        // Use the value as decoded (arrays are automatically split).
                        //
                        locationSearchParameters[key] = value
                    }
                }
            }
        }
        //
        // Check active checkbox list filters. When loading the page the cache is empty
        // and not all list entries will be available. Use the refire-approach to load
        // all results to get the list entries and then get the filtered search results.
        //
        const checkboxListParameters = {}
        Object.keys(locationSearchParameters)
            .filter(key => (activeConfig.filters[key]?.type || '') === Constants.filterType.checkboxlist)
            .forEach(filterName => {
                checkboxListParameters[filterName] = locationSearchParameters[filterName]
                delete locationSearchParameters[filterName]
            })
        //
        // Check if re-firing the search is necessary based on filter
        // preservation settings.
        //
        if (activeConfig.searchResults?.preserveFilters) {
            Object.keys(locationSearchParameters)
                .filter(filterName => Object.keys(activeConfig.filters).includes(filterName))
                .forEach(filterName => {
                    checkboxListParameters[filterName] = locationSearchParameters[filterName]
                    delete locationSearchParameters[filterName]
                })
        }
        //
        // Repeating search becomes necessary if list entries need to
        // be retrieved or all filters need to be preserved.
        //
        var repeatSearch = (Object.keys(checkboxListParameters).length > 0)
        //
        // Update the search parameters held in the state and submit the search request.
        //
        if (locationSearchParameters[Constants.parameter.didYouMean]) {
            delete locationSearchParameters[Constants.parameter.didYouMean]
            search.pushHistory(locationSearchParameters)
        }
        //
        // Make sure to show the first (Lucidworks) results tab.
        //
        if (locationSearchParameters[Constants.parameter.activeSource] && (locationSearchParameters[Constants.parameter.activeSource] !== Constants.resultSource.lucidworks)) {
            locationSearchParameters[Constants.parameter.activeSource] = Constants.resultSource.lucidworks
            search.pushHistory(locationSearchParameters)
        }
        setSearchParameters(locationSearchParameters)
        //
        // Logic for the Single Sign On (SSO): If the login hasn't been
        // checked already, redirect for SSO.
        //
        // 04.09.2024: Disabled due to issues during first go-live.
        //
        // const uniqueID = Cookies.get('uniqueID')
        // if (uniqueID && !locationSearchParameters[Constants.parameter.loginChecked]) {
        //     const loginUrl = new URL(`${Constants.apiVersion}/login/saml${window.location.search}`, window.location.origin)
        //     window.location.href = loginUrl.href
        // } else {
            setCanSearch(true)
        // }
        Util.submitPostRequest(`${Constants.apiVersion}/search`, locationSearchParameters).then((newSearchResult) => {
            //
            // For each result source, validate the amount of documents found against
            // the page parameter. If there are not enough results, update the page
            // value and enable re-firing the search request.
            //
            resultSources.forEach(resultSource => {
                const pageParameter = Util.getPageParameter(resultSource)
                const result = newSearchResult[resultSource]?.result || {}
                const docsCount = locationSearchParameters[Constants.parameter.docsCount] || Constants.defaultParameterValue[Constants.parameter.docsCount]
                const pagesFound = ((result?.total || 0) / docsCount) + 1
                const page = locationSearchParameters[pageParameter] || 1
                if ((page > 1) && (page > pagesFound)) {
                    locationSearchParameters[pageParameter] = pagesFound
                    repeatSearch = true
                }
            })
            //
            // Send signals about the search request.
            //
            Util.submitPostRequest(`${Constants.apiVersion}/signals`, {
                signal: Constants.signal.locationChanged,
                searchParameters: locationSearchParameters,
            }).then(response => {
                if (response?.status !== 'ok') {
                    console.error(`Unexpected signals response from middleware: ${JSON.stringify(response)}`)
                }
            }).catch(error => {
                console.error(`Signals error: ${error}`)
            })
            //
            // If needed, cache checkbox list filter entries, repeat the search,
            // and store the search results.
            //
            if (repeatSearch) {
                //
                // Check the results from each result source for checkbox list filters (their
                // value has been removed previously so that the full list will be returned).
                // Cache the list entries so that the full list can be displayed (selected
                // AND non-selected values).
                //
                Object.values(newSearchResult).forEach(resultSource => {
                    const facets = resultSource.result?.facets || {}
                    Object.keys(checkboxListParameters).forEach(filterName => {
                        //
                        // If the facet exists, cache its list entries.
                        //
                        if (facets[filterName]) {
                            setCachedFilters({
                                ...cachedFilters,
                                [filterName]: {
                                    searchParameters: locationSearchParameters,
                                    filterValues: facets[filterName]
                                }
                            })
                        }
                        //
                        // Restore the search parameters so the the search result list is
                        // properly filtered.
                        //
                        locationSearchParameters[filterName] = checkboxListParameters[filterName]
                    })
                })
                //
                // Repeat the search with the updated parameters.
                //
                setSearchParameters(locationSearchParameters)
                Util.submitPostRequest(`${Constants.apiVersion}/search`, locationSearchParameters).then((refireSearchResult) => {
                    setSearchResult(refireSearchResult)
                    setpreviousFacets(refireSearchResult[Constants.resultSource.lucidworks].result?.facets || {})
                    //
                    // Set the filter panel search results depending on the preserve
                    // filters configuration.
                    //
                    setFilterResult(activeConfig.searchResults?.preserveFilters ? newSearchResult : refireSearchResult)
                    //
                    // Track the search request/result.
                    //
                    const lucidworksResult = refireSearchResult[Constants.resultSource.lucidworks] || {}
                    if (lucidworksResult.result) {
                        if (lucidworksResult.result.total === 0) {
                            search.startNewSearch(locationSearchParameters[Constants.parameter.queryText])
                        }else{
                            search.setDigitalData(locationSearchParameters, lucidworksResult, 'success', true)
                        }
                    }
                }).catch(error => {
                    setSearchResult({
                        error: Labels.ErrorMessage.Error(error)
                    })
                    setpreviousFacets({})
                })
            } else {
                setSearchResult(newSearchResult)
                setFilterResult(newSearchResult)
                setpreviousFacets(newSearchResult[Constants.resultSource.lucidworks].result?.facets || {})
                //
                // If there is a response from Lucidworks AND the result count is 0, trigger
                // "did you mean".
                //
                const lucidworksResult = newSearchResult[Constants.resultSource.lucidworks] || {}
                if (lucidworksResult.result && (lucidworksResult.result.total === 0)) {
                    search.setDigitalData(locationSearchParameters, lucidworksResult, 'no match')
                    if (lucidworksResult.result.didYouMean) {
                        //
                        // Update the search parameters and...
                        //
                        const updatedSearchParameters = {
                            ...locationSearchParameters,
                            [Constants.parameter.didYouMean]: lucidworksResult.result.didYouMean
                        }
                        setSearchParameters(updatedSearchParameters)
                        //
                        // ...use them to repeat the search with "did you mean" enabled.
                        //
                        search.sendSignal(Constants.signal.didYouMeanTriggered, updatedSearchParameters, [ {
                            [Constants.parameter.didYouMean]: updatedSearchParameters[Constants.parameter.didYouMean]
                        }])
                        Util.submitPostRequest(`${Constants.apiVersion}/search`, updatedSearchParameters).then(dymSearchResult => {
                            setpreviousFacets(dymSearchResult[Constants.resultSource.lucidworks].result?.facets || {})
                            setSearchResult(dymSearchResult)
                            setFilterResult(dymSearchResult)
                            const lucidworksResultDym = dymSearchResult[Constants.resultSource.lucidworks] || {}
                            if (lucidworksResultDym.result && (lucidworksResultDym.result.total === 0)) {
                                //
                                // Logic for no results founds
                                //
                                const updatedSearchParameters = {
                                    ...locationSearchParameters,
                                    [Constants.parameter.didYouMean]: Constants.didYouMean.noSuggestionsAvailable
                                }
                                setSearchParameters(updatedSearchParameters)
                                search.sendSignal(Constants.signal.didYouMeanTriggered, updatedSearchParameters, [ {
                                    [Constants.parameter.didYouMean]: updatedSearchParameters[Constants.parameter.didYouMean]
                                }])
                            }
                        }).catch(error => {
                            setSearchResult({
                                error: Labels.ErrorMessage.Error(error)
                            })
                            setpreviousFacets({})
                        })
                    } else {
                        //
                        // Logic for no results founds
                        //
                        const updatedSearchParameters = {
                            ...locationSearchParameters,
                            [Constants.parameter.didYouMean]: Constants.didYouMean.noSuggestionsAvailable
                        }
                        setSearchParameters(updatedSearchParameters)
                        search.sendSignal(Constants.signal.didYouMeanTriggered, updatedSearchParameters, [ {
                            [Constants.parameter.didYouMean]: updatedSearchParameters[Constants.parameter.didYouMean]
                        }])
                    }
                } else if (lucidworksResult.result === undefined) {
                    //
                    // Logic for no results founds
                    //
                    const updatedSearchParameters = {
                        ...locationSearchParameters,
                        [Constants.parameter.didYouMean]: Constants.didYouMean.noSuggestionsAvailable
                    }
                    setSearchParameters(updatedSearchParameters)
                    search.setDigitalData(locationSearchParameters, lucidworksResult, 'no match')
                } else {
                    search.setDigitalData(locationSearchParameters, lucidworksResult, 'success')
                }
            }
            //
            // Send signal - filters in the URL.
            //
            const configuredFilters = activeConfig?.filters || {}
            var filterParameters = Object.keys(locationSearchParameters).filter(parameter => Object.keys(configuredFilters).includes(parameter))
            const activeFilters = filterParameters.filter(filterName => {
                const filterValues = locationSearchParameters[filterName];
                return filterValues && filterValues.length > 0 && filterValues.some(value => value !== "");
            });
            if (activeFilters.length > 0) {
                search.sendSignal(Constants.signal.filterAdded, locationSearchParameters, activeFilters.map(activeFilterName => ({
                    name: activeFilterName,
                    values: locationSearchParameters[activeFilterName],
                })))
            }
        }).catch(error => {
            setSearchResult({
                error: Labels.ErrorMessage.Error(error)
            })
            setpreviousFacets({})
        })
    }
    //
    // Load the configuration, apply URL paramaters, and register event handlers for navigation
    // buttons. Force single execution by adding empty array as second parameter.
    // https://stackoverflow.com/questions/53120972/how-to-call-loading-function-with-react-useeffect-only-once
    //
    useEffect(() => {
        //
        // Load the configuration.
        //
        Util.submitRequest(`${Constants.apiVersion}/config`).then((activeConfig) => {
            //
            // Add valid parameter values to the configuration and store it.
            //
            activeConfig = {
                ...activeConfig,
                validParameterValues: {
                    [Constants.parameter.activeSource]: activeConfig.sources,
                    [Constants.parameter.language]: {
                        defaultValue: Constants.defaultParameterValue[Constants.parameter.language],
                        values: Constants.languages
                    },
                }
            }
            //
            // Apply search parameters found in the browser address.
            //
            applySearchParameters(window.location, activeConfig)
            //
            // Setting the configuration
            //
            setConfig(activeConfig)
            //
            // Register a listener for the 'popstate' event (click on the 'back' and 'forward' buttons).
            //
            window.addEventListener('popstate', (event) => {
                applySearchParameters(event.target.location, activeConfig)
            }, false)
            //
            // Include Adobe Analytics "launch script" (if configured and enabled).
            //
            // if (activeConfig.adobeAnalytics?.enabled) {
            //     const adobeAnalyticsScript = activeConfig.adobeAnalytics?.launchScript
            //     if (adobeAnalyticsScript) {
            //         const adobeAnalyticsElement = document.createElement('script')
            //         adobeAnalyticsElement.src = adobeAnalyticsScript
            //         adobeAnalyticsElement.async = true
            //         document.body.appendChild(adobeAnalyticsElement)
            //     }
            // }
        }).catch(error => {
            setErrorMessage(`${Util.getLabel(Labels.App.FailedToLoadConfig)}. ${Labels.ErrorMessage.Error(error)}`)
        })
        //
        // Fetch footer JSON from API, checking the URL for a language parameter first.
        //
        var language = Constants.defaultParameterValue[Constants.parameter.language]
        if (window.location.search) {
            for (var [key, value] of new URLSearchParams(window.location.search).entries()) {
                if (key === Constants.parameter.language) {
                    language = value
                    break
                }
            }
        }
        Util.submitRequest(`${Constants.apiVersion}/footer?${Constants.parameter.language}=${language}`)
        .then((json) => {
            setFooterJson(json);
        })
        .catch((error) => {
            setErrorMessage(`${Util.getLabel(Labels.App.FailedToLoadConfig)}. ${Labels.ErrorMessage.Error(error)}`)
        });
        //
        // Fetch newslettert HTML from API
        //
        Util.submitRequest(`${Constants.apiVersion}/newsletter?${Constants.parameter.language}=${language}`)
        .then((html) => {
            setFooterNewsletterHTML(html)
        })
        .catch((error) => {
            setErrorMessage(`${Util.getLabel(Labels.App.FailedToLoadConfig)}. ${Labels.ErrorMessage.Error(error)}`)
        });
        //
        // Handle unexpected errors
        //
        window.addEventListener('error', (event) => {
            if(!(event?.message === "Script error.")) {
                setErrorMessage(`${Util.getLabel(Labels.App.FailedToLoadConfig)}. ${Labels.ErrorMessage.Error(event?.error?.message || 'Error occurred')}`)
                search.startNewSearch('')
            }
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    //
    // Logic for loading.
    //
    const setLoadingState = (newLoadingState) => {
        setLoading(newLoadingState);
    };
    const setDocFilterLoadingState = (newDocFilterLoadingState) => {
        setDocFilterLoading(newDocFilterLoadingState);
    };
    const setResultXrefState = (value) => {
        setResultXref(value);
    };
    const setNoResultState = (value) => {
        setNoResult(value);
    };
    const setCacheNoResultState = (value) => {
        setCacheNoResult(value);
    };
    useEffect(() => {
        setLoadingState(true);
        if (Object.keys(searchResult).length > 0) {
            setLoadingState(false);
            setDocFilterLoading({});
        }
        setResultXrefState(0);
    }, [searchResult]);
    //
    // Render the UI.
    //
    return (
        <ThemeProvider theme={CustomTheme}>
            <div className='App'>
                { (config && canSearch && footerJson) ?
                    <div>
                        <div className='HeaderContainer'>
                            <SearchBox
                                cachedFilters={cachedFilters}
                                cacheNoResult={cacheNoResult}
                                config={config}
                                filterResult={filterResult}
                                isloading={loading}
                                searchParameters={searchParameters}
                                searchResult={searchResult}
                                setCachedFilters={setCachedFilters}
                                setFilterResult={setFilterResult}
                                setSearchParameters={setSearchParameters}
                                setSearchResult={setSearchResult}
                                setpreviousFacets={setpreviousFacets}
                                setIsFirstClick={setIsFirstClick}
                                setIsFirstClickXRef={setIsFirstClickXRef}
                            />
                        </div>
                        { (config.allowEmptySearch || searchParameters[Constants.parameter.queryText]) &&
                            <div className='MainContainer'>
                                {(
                                    loading || (!(Object.keys(searchResult).length > 0)) ? (
                                        <>
                                            <Loading
                                                config={config}
                                                cachedFilters={cachedFilters}
                                                docFilterLoading={docFilterLoading}
                                                footerJson={footerJson}
                                                footerNewsletterHTML={footerNewsletterHTML}
                                                searchParameters={searchParameters}
                                                searchResult={searchResult}
                                                setCachedFilters={setCachedFilters}
                                                setFooterJson={setFooterJson}
                                                setSearchParameters={setSearchParameters}
                                                previousFacets={previousFacets}
                                            />
                                            {stateActiveSidebar && (<Sidebar
                                                cachedFilters={cachedFilters}
                                                cacheNoResult={cacheNoResult}
                                                config={config}
                                                docFilterLoading={docFilterLoading}
                                                resultXref={resultXref}
                                                searchParameters={searchParameters}
                                                searchResult={searchResult}
                                                setCachedFilters={setCachedFilters}
                                                setCacheNoResultState={setCacheNoResultState}
                                                setDocFilterLoadingState={setDocFilterLoadingState}
                                                setFilterResult={setFilterResult}
                                                setLoadingState={setLoadingState}
                                                setSearchParameters={setSearchParameters}
                                                setSearchResult={setSearchResult}
                                                setpreviousFacets={setpreviousFacets}
                                                setStateActiveSidebar={setStateActiveSidebar}
                                                stateActiveSidebar={stateActiveSidebar}
                                                statePrevTotalResult={statePrevTotalResult}
                                                previousFacets={previousFacets}
                                            />)}
                                        </>
                                    ) : (
                                        <div className='SearchResultsPanel'>
                                            <SearchResultsPanel
                                                cachedFilters={cachedFilters}
                                                cacheNoResult={cacheNoResult}
                                                config={config}
                                                docFilterLoading={docFilterLoading}
                                                filterResult={filterResult}
                                                noResult={noResult}
                                                resultXref={resultXref}
                                                searchParameters={searchParameters}
                                                searchResult={searchResult}
                                                previousFacets={previousFacets}
                                                setCachedFilters={setCachedFilters}
                                                setCacheNoResultState={setCacheNoResultState}
                                                setDocFilterLoadingState={setDocFilterLoadingState}
                                                setLoadingState={setLoadingState}
                                                setFilterResult={setFilterResult}
                                                setNoResultState={setNoResultState}
                                                setResultXrefState={setResultXrefState}
                                                setSearchParameters={setSearchParameters}
                                                setSearchResult={setSearchResult}
                                                setpreviousFacets={setpreviousFacets}
                                                setStateActiveSidebar={setStateActiveSidebar}
                                                setStatePrevTotalResult={setStatePrevTotalResult}
                                                stateActiveSidebar={stateActiveSidebar}
                                                isFirstClick={isFirstClick}
                                                setIsFirstClick={setIsFirstClick}
                                                isFirstClickXRef={isFirstClickXRef}
                                                setIsFirstClickXRef={setIsFirstClickXRef}
                                            />
                                        </div>
                                    )
                                )}
                            </div>
                        }
                        <div className='FooterContainer'>
                            <Footer
                                footerJson={footerJson}
                                footerNewsletterHTML={footerNewsletterHTML}
                            />
                        </div>
                    </div>
                :
                    (errorMessage !== '' &&
                        <ErrorMessage
                            message={errorMessage}
                            searchParameters={searchParameters}
                        />
                    )
                }
            </div>
        </ThemeProvider>
    )
}

export default App