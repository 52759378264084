import Constants from './Constants'
//
// UI labels: The first level variable names reflect the component class names
// and the second level ones the name of the label used inside the component.
//
class Labels {

    static ActiveFilters = {
        ActiveFilters: {
            [Constants.iso_639_1.chinese]: '当前筛选器',
            [Constants.iso_639_1.english]: 'Filters',
            [Constants.iso_639_1.french]: 'Filtres actifs',
            [Constants.iso_639_1.german]: 'Aktive Filter',
            [Constants.iso_639_1.japanese]: 'フィルター',
        },
        ClearAllFilters: {
            [Constants.iso_639_1.chinese]: '清除所有筛选器',
            [Constants.iso_639_1.english]: 'Clear filters',
            [Constants.iso_639_1.french]: 'Supprimer les filtres',
            [Constants.iso_639_1.german]: 'Filter löschen',
            [Constants.iso_639_1.japanese]: 'クリアフィルター',
        },
    }

    static App = {
        FailedToLoadConfig: {
            [Constants.iso_639_1.chinese]: '加载配置失败',
            [Constants.iso_639_1.english]: 'Failed to load configuration',
            [Constants.iso_639_1.french]: 'Échec du chargement de la configuration',
            [Constants.iso_639_1.german]: 'Konfiguration konnte nicht geladen werden',
            [Constants.iso_639_1.japanese]: '設定のロードに失敗しました',
        }
    }

    static Constants = {
        Language: {
            [Constants.iso_639_1.chinese]: '中文',
            [Constants.iso_639_1.english]: 'English',
            [Constants.iso_639_1.french]: 'Français',
            [Constants.iso_639_1.german]: 'Deutsch',
            [Constants.iso_639_1.japanese]: '日本語',
        }
    }

    static DocumentType = {
        [Constants.documentType.products]: {
            [Constants.iso_639_1.chinese]: '产品',
            [Constants.iso_639_1.english]: 'Products',
            [Constants.iso_639_1.french]: 'Produits',
            [Constants.iso_639_1.german]: 'Produkte',
            [Constants.iso_639_1.japanese]: '製品',
        },
        [Constants.documentType.tools]: {
            [Constants.iso_639_1.chinese]: '工具',
            [Constants.iso_639_1.english]: 'Tools',
            [Constants.iso_639_1.french]: 'Outils',
            [Constants.iso_639_1.german]: 'Werkzeuge',
            [Constants.iso_639_1.japanese]: 'ツール',
        },
        [Constants.documentType.solutions]: {
            [Constants.iso_639_1.chinese]: '解决方案',
            [Constants.iso_639_1.english]: 'Solutions',
            [Constants.iso_639_1.french]: 'Solutions',
            [Constants.iso_639_1.german]: 'Lösungen',
            [Constants.iso_639_1.japanese]: 'ソリューション',
        },
        [Constants.documentType.stm32DeveloperZone]: {
            [Constants.iso_639_1.chinese]: 'STM32开发者社区',
            [Constants.iso_639_1.english]: 'STM32 Developer Zone',
            [Constants.iso_639_1.french]: 'Zone de développeurs STM32',
            [Constants.iso_639_1.german]: 'STM32 Entwicklerzone',
            [Constants.iso_639_1.japanese]: 'STM32 デベロッパーゾーン',
        },
        [Constants.documentType.wiki]: {
            [Constants.iso_639_1.chinese]: '维基',
            [Constants.iso_639_1.english]: 'Wiki',
            [Constants.iso_639_1.french]: 'Wiki',
            [Constants.iso_639_1.german]: 'Wiki',
            [Constants.iso_639_1.japanese]: 'ウィキ',
        },
        [Constants.documentType.resources]: {
            [Constants.iso_639_1.chinese]: '资源',
            [Constants.iso_639_1.english]: 'Resources',
            [Constants.iso_639_1.french]: 'Ressources',
            [Constants.iso_639_1.german]: 'Ressourcen',
            [Constants.iso_639_1.japanese]: 'リソース',
        },
        [Constants.documentType.keyResources]: {
            [Constants.iso_639_1.chinese]: '关键资源',
            [Constants.iso_639_1.english]: 'KeyResources',
            [Constants.iso_639_1.french]: 'Ressources clés',
            [Constants.iso_639_1.german]: 'Schlüsselressourcen',
            [Constants.iso_639_1.japanese]: 'キーリソース',
        },
        [Constants.documentType.applications]: {
            [Constants.iso_639_1.chinese]: '应用程序',
            [Constants.iso_639_1.english]: 'Applications',
            [Constants.iso_639_1.french]: 'Applications',
            [Constants.iso_639_1.german]: 'Anwendungen',
            [Constants.iso_639_1.japanese]: 'アプリケーション',
        },
        [Constants.documentType.partnerProducts]: {
            [Constants.iso_639_1.chinese]: '合作伙伴产品',
            [Constants.iso_639_1.english]: 'Partner Products',
            [Constants.iso_639_1.french]: 'Produits partenaires',
            [Constants.iso_639_1.german]: 'Partnerprodukte',
            [Constants.iso_639_1.japanese]: 'パートナープロダクト',
        },
        [Constants.documentType.software]: {
            [Constants.iso_639_1.chinese]: '软件',
            [Constants.iso_639_1.english]: 'Software',
            [Constants.iso_639_1.french]: 'Logiciel',
            [Constants.iso_639_1.german]: 'Software',
            [Constants.iso_639_1.japanese]: 'ソフトウェア',
        },
        [Constants.documentType.blogs]: {
            [Constants.iso_639_1.chinese]: '博客',
            [Constants.iso_639_1.english]: 'Blogs',
            [Constants.iso_639_1.french]: 'Blogs',
            [Constants.iso_639_1.german]: 'Blogs',
            [Constants.iso_639_1.japanese]: 'ブログ',
        },
        [Constants.documentType.learning]: {
            [Constants.iso_639_1.chinese]: '学习',
            [Constants.iso_639_1.english]: 'Learning',
            [Constants.iso_639_1.french]: 'Apprentissage',
            [Constants.iso_639_1.german]: 'Lernen',
            [Constants.iso_639_1.japanese]: '学習',
        },
        [Constants.documentType.news]: {
            [Constants.iso_639_1.chinese]: '新闻',
            [Constants.iso_639_1.english]: 'News',
            [Constants.iso_639_1.french]: 'Actualités',
            [Constants.iso_639_1.german]: 'Nachrichten',
            [Constants.iso_639_1.japanese]: 'ニュース',
        },
        [Constants.documentType.support]: {
            [Constants.iso_639_1.chinese]: '支持',
            [Constants.iso_639_1.english]: 'Support',
            [Constants.iso_639_1.french]: 'Support',
            [Constants.iso_639_1.german]: 'Unterstützung',
            [Constants.iso_639_1.japanese]: 'サポート',
        },
        [Constants.documentType.other]: {
            [Constants.iso_639_1.chinese]: '其他',
            [Constants.iso_639_1.english]: 'Other',
            [Constants.iso_639_1.french]: 'Autre',
            [Constants.iso_639_1.german]: 'Andere',
            [Constants.iso_639_1.japanese]: 'その他',
        },
        [Constants.documentType.video]: {
            [Constants.iso_639_1.chinese]: '视频',
            [Constants.iso_639_1.english]: 'Video',
            [Constants.iso_639_1.french]: 'Vidéo',
            [Constants.iso_639_1.german]: 'Video',
            [Constants.iso_639_1.japanese]: 'ビデオ',
        },
        [Constants.documentType.events]: {
            [Constants.iso_639_1.chinese]: '活动',
            [Constants.iso_639_1.english]: 'Events',
            [Constants.iso_639_1.french]: 'Événements',
            [Constants.iso_639_1.german]: 'Veranstaltungen',
            [Constants.iso_639_1.japanese]: 'イベント',
        },
        [Constants.documentType.technology]: {
            [Constants.iso_639_1.chinese]: '技术',
            [Constants.iso_639_1.english]: 'Technology',
            [Constants.iso_639_1.french]: 'Technologie',
            [Constants.iso_639_1.german]: 'Technologie',
            [Constants.iso_639_1.japanese]: 'テクノロジー',
        },
        [Constants.documentType.community]: {
            [Constants.iso_639_1.chinese]: '社区',
            [Constants.iso_639_1.english]: 'Community',
            [Constants.iso_639_1.french]: 'Communauté',
            [Constants.iso_639_1.german]: 'Gemeinschaft',
            [Constants.iso_639_1.japanese]: 'コミュニティ',
        },
        [Constants.documentType.estore]: {
            [Constants.iso_639_1.chinese]: '电子商店',
            [Constants.iso_639_1.english]: 'EStore',
            [Constants.iso_639_1.french]: 'Boutique en ligne',
            [Constants.iso_639_1.german]: 'Online-Shop',
            [Constants.iso_639_1.japanese]: 'オンラインストア',
        },
        [Constants.documentType.partner]: {
            [Constants.iso_639_1.chinese]: '合作伙伴',
            [Constants.iso_639_1.english]: 'Partner',
            [Constants.iso_639_1.french]: 'Partenaire',
            [Constants.iso_639_1.german]: 'Partner',
            [Constants.iso_639_1.japanese]: 'パートナー',
        },
    }

    static TypeLabel = {
        [Constants.typeLabel.productCategory]: {
            [Constants.iso_639_1.chinese]: '产品类别',
            [Constants.iso_639_1.english]: 'Product Category',
            [Constants.iso_639_1.french]: 'Catégorie de Produit',
            [Constants.iso_639_1.german]: 'Produktkategorie',
            [Constants.iso_639_1.japanese]: '製品カテゴリ',
        },
        [Constants.typeLabel.applicationCategory]: {
            [Constants.iso_639_1.chinese]: '应用类别',
            [Constants.iso_639_1.english]: 'Application Category',
            [Constants.iso_639_1.french]: "Catégorie d'Application",
            [Constants.iso_639_1.german]: 'Anwendungskategorie',
            [Constants.iso_639_1.japanese]: 'アプリケーションカテゴリ',
        },
        [Constants.typeLabel.solutionCategory]: {
            [Constants.iso_639_1.chinese]: '解决方案类别',
            [Constants.iso_639_1.english]: 'Solution Category',
            [Constants.iso_639_1.french]: 'Catégorie de Solution',
            [Constants.iso_639_1.german]: 'Lösungskategorie',
            [Constants.iso_639_1.japanese]: 'ソリューションカテゴリ',
        },
        [Constants.typeLabel.toolsCategory]: {
            [Constants.iso_639_1.chinese]: '工具类别',
            [Constants.iso_639_1.english]: 'Tools Category',
            [Constants.iso_639_1.french]: "Catégorie d'Outils",
            [Constants.iso_639_1.german]: 'Werkzeugkategorie',
            [Constants.iso_639_1.japanese]: 'ツールカテゴリ',
        },
        [Constants.typeLabel.softwareCategory]: {
            [Constants.iso_639_1.chinese]: '软件类别',
            [Constants.iso_639_1.english]: 'Software Category',
            [Constants.iso_639_1.french]: 'Catégorie de Logiciel',
            [Constants.iso_639_1.german]: 'Softwarekategorie',
            [Constants.iso_639_1.japanese]: 'ソフトウェアカテゴリ',
        },
        [Constants.typeLabel.partnerProductsCategory]: {
            [Constants.iso_639_1.chinese]: '合作伙伴产品类别',
            [Constants.iso_639_1.english]: 'Partner Products Category',
            [Constants.iso_639_1.french]: 'Catégorie de Produits Partenaires',
            [Constants.iso_639_1.german]: 'Partnerproduktkategorie',
            [Constants.iso_639_1.japanese]: 'パートナープロダクトカテゴリ',
        },
    }

    static ResourcesType = {
        [Constants.resourcesType.dataBrief]: {
            [Constants.iso_639_1.chinese]: '数据简报',
            [Constants.iso_639_1.english]: 'Data Brief',
            [Constants.iso_639_1.french]: 'Résumé des données',
            [Constants.iso_639_1.german]: 'Datenblatt',
            [Constants.iso_639_1.japanese]: 'データブリーフ',
        },
        [Constants.resourcesType.materialDeclaration]: {
            [Constants.iso_639_1.chinese]: '材料声明',
            [Constants.iso_639_1.english]: 'Material Declaration',
            [Constants.iso_639_1.french]: 'Déclaration de matériel',
            [Constants.iso_639_1.german]: 'Materialdeklaration',
            [Constants.iso_639_1.japanese]: '材料宣言',
        },
        [Constants.resourcesType.applicationNote]: {
            [Constants.iso_639_1.chinese]: '应用说明',
            [Constants.iso_639_1.english]: 'Application Note',
            [Constants.iso_639_1.french]: 'Note d\'application',
            [Constants.iso_639_1.german]: 'Anwendungshinweis',
            [Constants.iso_639_1.japanese]: 'アプリケーションノート',
        },
        [Constants.resourcesType.datasheet]: {
            [Constants.iso_639_1.chinese]: '数据表',
            [Constants.iso_639_1.english]: 'Datasheet',
            [Constants.iso_639_1.french]: 'Fiche technique',
            [Constants.iso_639_1.german]: 'Datenblatt',
            [Constants.iso_639_1.japanese]: 'データシート',
        },
        [Constants.resourcesType.billOfMaterials]: {
            [Constants.iso_639_1.chinese]: '物料清单',
            [Constants.iso_639_1.english]: 'Bill Of Materials',
            [Constants.iso_639_1.french]: 'Liste de matériaux',
            [Constants.iso_639_1.german]: 'Stückliste',
            [Constants.iso_639_1.japanese]: '部品表',
        },
        [Constants.resourcesType.designTip]: {
            [Constants.iso_639_1.chinese]: '设计提示',
            [Constants.iso_639_1.english]: 'Design Tip',
            [Constants.iso_639_1.french]: 'Conseil de conception',
            [Constants.iso_639_1.german]: 'Design-Tipp',
            [Constants.iso_639_1.japanese]: '設計のヒント',
        },
        [Constants.resourcesType.boardManufacturingSpecification]: {
            [Constants.iso_639_1.chinese]: '板制造规范',
            [Constants.iso_639_1.english]: 'Board Manufacturing Specification',
            [Constants.iso_639_1.french]: 'Spécification de fabrication de carte',
            [Constants.iso_639_1.german]: 'Herstellungsspezifikation für Platinen',
            [Constants.iso_639_1.japanese]: '基板製造仕様',
        },
        [Constants.resourcesType.userManual]: {
            [Constants.iso_639_1.chinese]: '用户手册',
            [Constants.iso_639_1.english]: 'User Manual',
            [Constants.iso_639_1.french]: 'Manuel utilisateur',
            [Constants.iso_639_1.german]: 'Benutzerhandbuch',
            [Constants.iso_639_1.japanese]: 'ユーザーマニュアル',
        },
        [Constants.resourcesType.flyer]: {
            [Constants.iso_639_1.chinese]: '传单',
            [Constants.iso_639_1.english]: 'Flyer',
            [Constants.iso_639_1.french]: 'Prospectus',
            [Constants.iso_639_1.german]: 'Flugblatt',
            [Constants.iso_639_1.japanese]: 'フライヤー',
        },
        [Constants.resourcesType.productTraining]: {
            [Constants.iso_639_1.chinese]: '产品培训',
            [Constants.iso_639_1.english]: 'Product Training',
            [Constants.iso_639_1.french]: 'Formation produit',
            [Constants.iso_639_1.german]: 'Produktschulung',
            [Constants.iso_639_1.japanese]: '製品トレーニング',
        },
        [Constants.resourcesType.companyPresentation]: {
            [Constants.iso_639_1.chinese]: '公司介绍',
            [Constants.iso_639_1.english]: 'Company Presentation',
            [Constants.iso_639_1.french]: 'Présentation de l\'entreprise',
            [Constants.iso_639_1.german]: 'Unternehmenspräsentation',
            [Constants.iso_639_1.japanese]: '会社紹介',
        },
        [Constants.resourcesType.applicationSw]: {
            [Constants.iso_639_1.chinese]: '应用软件',
            [Constants.iso_639_1.english]: 'Application Sw',
            [Constants.iso_639_1.french]: 'Logiciel d\'application',
            [Constants.iso_639_1.german]: 'Anwendungssoftware',
            [Constants.iso_639_1.japanese]: 'アプリケーションソフト',
        },
        [Constants.resourcesType.productVideo]: {
            [Constants.iso_639_1.chinese]: '产品视频',
            [Constants.iso_639_1.english]: 'Product Video',
            [Constants.iso_639_1.french]: 'Vidéo produit',
            [Constants.iso_639_1.german]: 'Produktvideo',
            [Constants.iso_639_1.japanese]: '製品ビデオ',
        },
        [Constants.resourcesType.firmware]: {
            [Constants.iso_639_1.chinese]: '固件',
            [Constants.iso_639_1.english]: 'Firmware',
            [Constants.iso_639_1.french]: 'Micrologiciel',
            [Constants.iso_639_1.german]: 'Firmware',
            [Constants.iso_639_1.japanese]: 'ファームウェア',
        },
        [Constants.resourcesType.technologyPresentation]: {
            [Constants.iso_639_1.chinese]: '技术介绍',
            [Constants.iso_639_1.english]: 'Technology Presentation',
            [Constants.iso_639_1.french]: 'Présentation de la technologie',
            [Constants.iso_639_1.german]: 'Technologiepräsentation',
            [Constants.iso_639_1.japanese]: '技術プレゼンテーション',
        },
        [Constants.resourcesType.schematicPack]: {
            [Constants.iso_639_1.chinese]: '电路图包',
            [Constants.iso_639_1.english]: 'Schematic Pack',
            [Constants.iso_639_1.french]: 'Pack schématique',
            [Constants.iso_639_1.german]: 'Schaltplanpaket',
            [Constants.iso_639_1.japanese]: '回路図パック',
        },
        [Constants.resourcesType.spiceModels]: {
            [Constants.iso_639_1.chinese]: 'SPICE模型',
            [Constants.iso_639_1.english]: 'SPICE models',
            [Constants.iso_639_1.french]: 'Modèles SPICE',
            [Constants.iso_639_1.german]: 'SPICE-Modelle',
            [Constants.iso_639_1.japanese]: 'SPICEモデル',
        },
        [Constants.resourcesType.technicalNote]: {
            [Constants.iso_639_1.chinese]: '技术说明',
            [Constants.iso_639_1.english]: 'Technical Note',
            [Constants.iso_639_1.french]: 'Note technique',
            [Constants.iso_639_1.german]: 'Technische Notiz',
            [Constants.iso_639_1.japanese]: '技術ノート',
        },
        [Constants.resourcesType.errataSheet]: {
            [Constants.iso_639_1.chinese]: '勘误表',
            [Constants.iso_639_1.english]: 'Errata Sheet',
            [Constants.iso_639_1.french]: 'Feuille d\'errata',
            [Constants.iso_639_1.german]: 'Errata',
            [Constants.iso_639_1.japanese]: '訂正表',
        },
        [Constants.resourcesType.cadSymbolAndFootprintModels]: {
            [Constants.iso_639_1.chinese]: 'CAD符号和封装模型',
            [Constants.iso_639_1.english]: 'CAD Symbol & Footprint models',
            [Constants.iso_639_1.french]: 'Modèles de symboles et empreintes CAD',
            [Constants.iso_639_1.german]: 'CAD-Symbole & Gehäusemodelle',
            [Constants.iso_639_1.japanese]: 'CADシンボルとフットプリントモデル',
        },
        [Constants.resourcesType.swDevelopmentSuite]: {
            [Constants.iso_639_1.chinese]: '软件开发套件',
            [Constants.iso_639_1.english]: 'Sw Development Suite',
            [Constants.iso_639_1.french]: 'Suite de développement logiciel',
            [Constants.iso_639_1.german]: 'Software-Entwicklungssuite',
            [Constants.iso_639_1.japanese]: 'ソフトウェア開発スイート',
        },
        [Constants.resourcesType.conferencePaper]: {
            [Constants.iso_639_1.chinese]: '会议论文',
            [Constants.iso_639_1.english]: 'Conference Paper',
            [Constants.iso_639_1.french]: 'Article de conférence',
            [Constants.iso_639_1.german]: 'Konferenzpapier',
            [Constants.iso_639_1.japanese]: '会議論文',
        },
        [Constants.resourcesType.certificationDocument]: {
            [Constants.iso_639_1.chinese]: '认证文件',
            [Constants.iso_639_1.english]: 'Certification Document',
            [Constants.iso_639_1.french]: 'Document de certification',
            [Constants.iso_639_1.german]: 'Zertifizierungsdokument',
            [Constants.iso_639_1.japanese]: '認定書',
        },
        [Constants.resourcesType.productPres]: {
            [Constants.iso_639_1.chinese]: '产品演示',
            [Constants.iso_639_1.english]: 'Product Pres',
            [Constants.iso_639_1.french]: 'Présentation produit',
            [Constants.iso_639_1.german]: 'Produktpräsentation',
            [Constants.iso_639_1.japanese]: '製品プレゼンテーション',
        },
        [Constants.resourcesType.demoVideo]: {
            [Constants.iso_639_1.chinese]: '演示视频',
            [Constants.iso_639_1.english]: 'Demo Video',
            [Constants.iso_639_1.french]: 'Vidéo de démonstration',
            [Constants.iso_639_1.german]: 'Demovideo',
            [Constants.iso_639_1.japanese]: 'デモビデオ',
        },
        [Constants.resourcesType.compiledDemos]: {
            [Constants.iso_639_1.chinese]: '编译的演示',
            [Constants.iso_639_1.english]: 'Compiled demos',
            [Constants.iso_639_1.french]: 'Démonstrations compilées',
            [Constants.iso_639_1.german]: 'Kompilierte Demos',
            [Constants.iso_639_1.japanese]: 'コンパイル済みデモ',
        },
        [Constants.resourcesType.library]: {
            [Constants.iso_639_1.chinese]: '库',
            [Constants.iso_639_1.english]: 'Library',
            [Constants.iso_639_1.french]: 'Bibliothèque',
            [Constants.iso_639_1.german]: 'Bibliothek',
            [Constants.iso_639_1.japanese]: 'ライブラリ',
        },
        [Constants.resourcesType.referenceManual]: {
            [Constants.iso_639_1.chinese]: '参考手册',
            [Constants.iso_639_1.english]: 'Reference Manual',
            [Constants.iso_639_1.french]: 'Manuel de référence',
            [Constants.iso_639_1.german]: 'Referenzhandbuch',
            [Constants.iso_639_1.japanese]: 'リファレンスマニュアル',
        },
        [Constants.resourcesType.utility]: {
            [Constants.iso_639_1.chinese]: '工具',
            [Constants.iso_639_1.english]: 'Utility',
            [Constants.iso_639_1.french]: 'Utilitaire',
            [Constants.iso_639_1.german]: 'Dienstprogramm',
            [Constants.iso_639_1.japanese]: 'ユーティリティ',
        },
        [Constants.resourcesType.programmingManual]: {
            [Constants.iso_639_1.chinese]: '编程手册',
            [Constants.iso_639_1.english]: 'Programming Manual',
            [Constants.iso_639_1.french]: 'Manuel de programmation',
            [Constants.iso_639_1.german]: 'Programmierhandbuch',
            [Constants.iso_639_1.japanese]: 'プログラミングマニュアル',
        },
        [Constants.resourcesType.brochure]: {
            [Constants.iso_639_1.chinese]: '宣传册',
            [Constants.iso_639_1.english]: 'Brochure',
            [Constants.iso_639_1.french]: 'Brochure',
            [Constants.iso_639_1.german]: 'Broschüre',
            [Constants.iso_639_1.japanese]: 'パンフレット',
        },
        [Constants.resourcesType.releaseNote]: {
            [Constants.iso_639_1.chinese]: '发行说明',
            [Constants.iso_639_1.english]: 'Release Note',
            [Constants.iso_639_1.french]: 'Note de version',
            [Constants.iso_639_1.german]: 'Versionshinweis',
            [Constants.iso_639_1.japanese]: 'リリースノート',
        },
        [Constants.resourcesType.technicalArticle]: {
            [Constants.iso_639_1.chinese]: '技术文章',
            [Constants.iso_639_1.english]: 'Technical Article',
            [Constants.iso_639_1.french]: 'Article technique',
            [Constants.iso_639_1.german]: 'Technischer Artikel',
            [Constants.iso_639_1.japanese]: '技術記事',
        },
        [Constants.resourcesType.demoAndExample]: {
            [Constants.iso_639_1.chinese]: '演示和示例',
            [Constants.iso_639_1.english]: 'Demo And Example',
            [Constants.iso_639_1.french]: 'Démo et exemple',
            [Constants.iso_639_1.german]: 'Demo und Beispiel',
            [Constants.iso_639_1.japanese]: 'デモと例',
        },
        [Constants.resourcesType.presentationVideo]: {
            [Constants.iso_639_1.chinese]: '演示视频',
            [Constants.iso_639_1.english]: 'Presentation Video',
            [Constants.iso_639_1.french]: 'Vidéo de présentation',
            [Constants.iso_639_1.german]: 'Präsentationsvideo',
            [Constants.iso_639_1.japanese]: 'プレゼンテーションビデオ',
        },
        [Constants.resourcesType.cadDesignFiles]: {
            [Constants.iso_639_1.chinese]: 'CAD设计文件',
            [Constants.iso_639_1.english]: 'CAD Design Files',
            [Constants.iso_639_1.french]: 'Fichiers de conception CAD',
            [Constants.iso_639_1.german]: 'CAD-Design-Dateien',
            [Constants.iso_639_1.japanese]: 'CAD設計ファイル',
        },
        [Constants.resourcesType.productPresentation]: {
            [Constants.iso_639_1.chinese]: '产品展示',
            [Constants.iso_639_1.english]: 'Product Presentation',
            [Constants.iso_639_1.french]: 'Présentation produit',
            [Constants.iso_639_1.german]: 'Produktpräsentation',
            [Constants.iso_639_1.japanese]: '製品プレゼンテーション',
        },
        [Constants.resourcesType.tpmCertificates]: {
            [Constants.iso_639_1.chinese]: 'TPM证书',
            [Constants.iso_639_1.english]: 'TPM Certificates',
            [Constants.iso_639_1.french]: 'Certificats TPM',
            [Constants.iso_639_1.german]: 'TPM-Zertifikate',
            [Constants.iso_639_1.japanese]: 'TPM証明書',
        },
        [Constants.resourcesType.additionalLicenseTerms]: {
            [Constants.iso_639_1.chinese]: '附加许可条款',
            [Constants.iso_639_1.english]: 'Additional License Terms',
            [Constants.iso_639_1.french]: 'Conditions de licence supplémentaires',
            [Constants.iso_639_1.german]: 'Zusätzliche Lizenzbedingungen',
            [Constants.iso_639_1.japanese]: '追加のライセンス条項',
        },
        [Constants.resourcesType.bsdlFiles]: {
            [Constants.iso_639_1.chinese]: 'BSDL文件',
            [Constants.iso_639_1.english]: 'BSDL Files',
            [Constants.iso_639_1.french]: 'Fichiers BSDL',
            [Constants.iso_639_1.german]: 'BSDL-Dateien',
            [Constants.iso_639_1.japanese]: 'BSDLファイル',
        },
        [Constants.resourcesType.licenseAgreement]: {
            [Constants.iso_639_1.chinese]: '许可协议',
            [Constants.iso_639_1.english]: 'License Agreement',
            [Constants.iso_639_1.french]: 'Accord de licence',
            [Constants.iso_639_1.german]: 'Lizenzvereinbarung',
            [Constants.iso_639_1.japanese]: 'ライセンス契約',
        },
        [Constants.resourcesType.deviceOptionList]: {
            [Constants.iso_639_1.chinese]: '设备选项列表',
            [Constants.iso_639_1.english]: 'Device Option List',
            [Constants.iso_639_1.french]: 'Liste des options de périphérique',
            [Constants.iso_639_1.german]: 'Geräteoptionenliste',
            [Constants.iso_639_1.japanese]: 'デバイスオプションリスト',
        },
        [Constants.resourcesType.ibisModels]: {
            [Constants.iso_639_1.chinese]: 'IBIS模型',
            [Constants.iso_639_1.english]: 'IBIS Models',
            [Constants.iso_639_1.french]: 'Modèles IBIS',
            [Constants.iso_639_1.german]: 'IBIS-Modelle',
            [Constants.iso_639_1.japanese]: 'IBISモデル',
        },
        [Constants.resourcesType.newsletter]: {
            [Constants.iso_639_1.chinese]: '新闻通讯',
            [Constants.iso_639_1.english]: 'Newsletter',
            [Constants.iso_639_1.french]: 'Bulletin',
            [Constants.iso_639_1.german]: 'Newsletter',
            [Constants.iso_639_1.japanese]: 'ニュースレター',
        },
        [Constants.resourcesType.whitePaper]: {
            [Constants.iso_639_1.chinese]: '白皮书',
            [Constants.iso_639_1.english]: 'White Paper',
            [Constants.iso_639_1.french]: 'Livre blanc',
            [Constants.iso_639_1.german]: 'Weißbuch',
            [Constants.iso_639_1.japanese]: 'ホワイトペーパー',
        },
        [Constants.resourcesType.adsModels]: {
            [Constants.iso_639_1.chinese]: 'ADS模型',
            [Constants.iso_639_1.english]: 'ADS Models',
            [Constants.iso_639_1.french]: 'Modèles ADS',
            [Constants.iso_639_1.german]: 'ADS-Modelle',
            [Constants.iso_639_1.japanese]: 'ADSモデル',
        },
        [Constants.resourcesType.driver]: {
            [Constants.iso_639_1.chinese]: '驱动程序',
            [Constants.iso_639_1.english]: 'Driver',
            [Constants.iso_639_1.french]: 'Pilote',
            [Constants.iso_639_1.german]: 'Treiber',
            [Constants.iso_639_1.japanese]: 'ドライバ',
        },
        [Constants.resourcesType.quarterlyReport]: {
            [Constants.iso_639_1.chinese]: '季度报告',
            [Constants.iso_639_1.english]: 'Quarterly Report',
            [Constants.iso_639_1.french]: 'Rapport trimestriel',
            [Constants.iso_639_1.german]: 'Quartalsbericht',
            [Constants.iso_639_1.japanese]: '四半期報告',
        },
        [Constants.resourcesType.middleware]: {
            [Constants.iso_639_1.chinese]: '中间件',
            [Constants.iso_639_1.english]: 'Middleware',
            [Constants.iso_639_1.french]: 'Middleware',
            [Constants.iso_639_1.german]: 'Middleware',
            [Constants.iso_639_1.japanese]: 'ミドルウェア',
        },
        [Constants.resourcesType.corporateBrochure]: {
            [Constants.iso_639_1.chinese]: '公司宣传册',
            [Constants.iso_639_1.english]: 'Corporate Brochure',
            [Constants.iso_639_1.french]: 'Brochure d\'entreprise',
            [Constants.iso_639_1.german]: 'Unternehmensbroschüre',
            [Constants.iso_639_1.japanese]: '企業パンフレット',
        },
        [Constants.resourcesType.salesAgreement]: {
            [Constants.iso_639_1.chinese]: '销售协议',
            [Constants.iso_639_1.english]: 'Sales Agreement',
            [Constants.iso_639_1.french]: 'Contrat de vente',
            [Constants.iso_639_1.german]: 'Verkaufsvereinbarung',
            [Constants.iso_639_1.japanese]: '販売契約',
        },
        [Constants.resourcesType.divisionalPresentation]: {
            [Constants.iso_639_1.chinese]: '部门演示',
            [Constants.iso_639_1.english]: 'Divisional Presentation',
            [Constants.iso_639_1.french]: 'Présentation divisionnaire',
            [Constants.iso_639_1.german]: 'Divisional-Präsentation',
            [Constants.iso_639_1.japanese]: '部門プレゼンテーション',
        },
        [Constants.resourcesType.designNote]: {
            [Constants.iso_639_1.chinese]: '设计说明',
            [Constants.iso_639_1.english]: 'Design Note',
            [Constants.iso_639_1.french]: 'Note de conception',
            [Constants.iso_639_1.german]: 'Entwurfsnotiz',
            [Constants.iso_639_1.japanese]: '設計ノート',
        },
        [Constants.resourcesType.hwDesignModels]: {
            [Constants.iso_639_1.chinese]: '硬件设计模型',
            [Constants.iso_639_1.english]: 'HW Design Models',
            [Constants.iso_639_1.french]: 'Modèles de conception HW',
            [Constants.iso_639_1.german]: 'HW-Design-Modelle',
            [Constants.iso_639_1.japanese]: 'HW設計モデル',
        },
        [Constants.resourcesType.simetrixModels]: {
            [Constants.iso_639_1.chinese]: 'SIMetrix模型',
            [Constants.iso_639_1.english]: 'SIMetrix Models',
            [Constants.iso_639_1.french]: 'Modèles SIMetrix',
            [Constants.iso_639_1.german]: 'SIMetrix-Modelle',
            [Constants.iso_639_1.japanese]: 'SIMetrixモデル',
        },
        [Constants.resourcesType.systemViewDescription]: {
            [Constants.iso_639_1.chinese]: '系统视图描述',
            [Constants.iso_639_1.english]: 'System View Description',
            [Constants.iso_639_1.french]: 'Description de la vue système',
            [Constants.iso_639_1.german]: 'Systemansichtsbeschreibung',
            [Constants.iso_639_1.japanese]: 'システムビューの説明',
        },
        [Constants.resourcesType.simplisModels]: {
            [Constants.iso_639_1.chinese]: 'SIMPLIS模型',
            [Constants.iso_639_1.english]: 'SIMPLIS Models',
            [Constants.iso_639_1.french]: 'Modèles SIMPLIS',
            [Constants.iso_639_1.german]: 'SIMPLIS-Modelle',
            [Constants.iso_639_1.japanese]: 'SIMPLISモデル',
        },
        [Constants.resourcesType.policyStatement]: {
            [Constants.iso_639_1.chinese]: '政策声明',
            [Constants.iso_639_1.english]: 'Policy Statement',
            [Constants.iso_639_1.french]: 'Déclaration de politique',
            [Constants.iso_639_1.german]: 'Politik-Erklärung',
            [Constants.iso_639_1.japanese]: 'ポリシーステートメント',
        },
        [Constants.resourcesType.demosResources]: {
            [Constants.iso_639_1.chinese]: '演示资源',
            [Constants.iso_639_1.english]: 'Demos Resources',
            [Constants.iso_639_1.french]: 'Ressources de démonstration',
            [Constants.iso_639_1.german]: 'Demonstrationsressourcen',
            [Constants.iso_639_1.japanese]: 'デモリソース',
        },
        [Constants.resourcesType.selectionGuide]: {
            [Constants.iso_639_1.chinese]: '选择指南',
            [Constants.iso_639_1.english]: 'Selection Guide',
            [Constants.iso_639_1.french]: 'Guide de sélection',
            [Constants.iso_639_1.german]: 'Auswahlhilfe',
            [Constants.iso_639_1.japanese]: '選択ガイド',
        },
        [Constants.resourcesType.verilogModels]: {
            [Constants.iso_639_1.chinese]: 'Verilog模型',
            [Constants.iso_639_1.english]: 'Verilog Models',
            [Constants.iso_639_1.french]: 'Modèles Verilog',
            [Constants.iso_639_1.german]: 'Verilog-Modelle',
            [Constants.iso_639_1.japanese]: 'Verilogモデル',
        },
        [Constants.resourcesType.eldoModels]: {
            [Constants.iso_639_1.chinese]: 'Eldo模型',
            [Constants.iso_639_1.english]: 'Eldo Models',
            [Constants.iso_639_1.french]: 'Modèles Eldo',
            [Constants.iso_639_1.german]: 'Eldo-Modelle',
            [Constants.iso_639_1.japanese]: 'Eldoモデル',
        },
        [Constants.resourcesType.applicationPresentation]: {
            [Constants.iso_639_1.chinese]: '应用演示',
            [Constants.iso_639_1.english]: 'Application Presentation',
            [Constants.iso_639_1.french]: 'Présentation de l\'application',
            [Constants.iso_639_1.german]: 'Anwendungspräsentation',
            [Constants.iso_639_1.japanese]: 'アプリケーションプレゼンテーション',
        },
        [Constants.resourcesType.technologyVideo]: {
            [Constants.iso_639_1.chinese]: '技术视频',
            [Constants.iso_639_1.english]: 'Technology Video',
            [Constants.iso_639_1.french]: 'Vidéo de technologie',
            [Constants.iso_639_1.german]: 'Technologievideo',
            [Constants.iso_639_1.japanese]: 'テクノロジービデオ',
        },
        [Constants.resourcesType.magazine]: {
            [Constants.iso_639_1.chinese]: '杂志',
            [Constants.iso_639_1.english]: 'Magazine',
            [Constants.iso_639_1.french]: 'Magazine',
            [Constants.iso_639_1.german]: 'Zeitschrift',
            [Constants.iso_639_1.japanese]: '雑誌',
        },
        [Constants.resourcesType.siteBrochure]: {
            [Constants.iso_639_1.chinese]: '网站宣传册',
            [Constants.iso_639_1.english]: 'Site Brochure',
            [Constants.iso_639_1.french]: 'Brochure du site',
            [Constants.iso_639_1.german]: 'Website-Broschüre',
            [Constants.iso_639_1.japanese]: 'サイトパンフレット',
        },
        [Constants.resourcesType.salesPresentation]: {
            [Constants.iso_639_1.chinese]: '销售演示',
            [Constants.iso_639_1.english]: 'Sales Presentation',
            [Constants.iso_639_1.french]: 'Présentation de vente',
            [Constants.iso_639_1.german]: 'Verkaufspräsentation',
            [Constants.iso_639_1.japanese]: '販売プレゼンテーション',
        },
        [Constants.resourcesType.gettingStartedGuides]: {
            [Constants.iso_639_1.chinese]: '入门指南',
            [Constants.iso_639_1.english]: 'Getting Started Guides',
            [Constants.iso_639_1.french]: 'Guides de démarrage',
            [Constants.iso_639_1.german]: 'Einführungsleitfäden',
            [Constants.iso_639_1.japanese]: '入門ガイド',
        },
        [Constants.resourcesType.utilities]: {
            [Constants.iso_639_1.chinese]: '实用程序',
            [Constants.iso_639_1.english]: 'Utilities',
            [Constants.iso_639_1.french]: 'Utilitaires',
            [Constants.iso_639_1.german]: 'Hilfsprogramme',
            [Constants.iso_639_1.japanese]: 'ユーティリティ',
        },
        [Constants.resourcesType.evaluationBoardTermsOfUse]: {
            [Constants.iso_639_1.chinese]: '评估板使用条款',
            [Constants.iso_639_1.english]: 'Evaluation Board Terms of Use',
            [Constants.iso_639_1.french]: 'Conditions d\'utilisation de la carte d\'évaluation',
            [Constants.iso_639_1.german]: 'Nutzungsbedingungen für Evaluierungsplatinen',
            [Constants.iso_639_1.japanese]: '評価ボードの利用規約',
        },
        [Constants.resourcesType.standardOperatingProcedure]: {
            [Constants.iso_639_1.chinese]: '标准操作程序',
            [Constants.iso_639_1.english]: 'Standard Operating Procedure',
            [Constants.iso_639_1.french]: 'Procédure opérationnelle standard',
            [Constants.iso_639_1.german]: 'Standardarbeitsanweisung',
            [Constants.iso_639_1.japanese]: '標準操作手順',
        },
        [Constants.resourcesType.codec]: {
            [Constants.iso_639_1.chinese]: '编解码器',
            [Constants.iso_639_1.english]: 'Codec',
            [Constants.iso_639_1.french]: 'Codec',
            [Constants.iso_639_1.german]: 'Codec',
            [Constants.iso_639_1.japanese]: 'コーデック',
        },
        [Constants.resourcesType.qualificationReport]: {
            [Constants.iso_639_1.chinese]: '资格报告',
            [Constants.iso_639_1.english]: 'Qualification Report',
            [Constants.iso_639_1.french]: 'Rapport de qualification',
            [Constants.iso_639_1.german]: 'Qualifikationsbericht',
            [Constants.iso_639_1.japanese]: '資格レポート',
        },
        [Constants.resourcesType.approvalStrategy]: {
            [Constants.iso_639_1.chinese]: '批准策略',
            [Constants.iso_639_1.english]: 'Approval Strategy',
            [Constants.iso_639_1.french]: 'Stratégie d\'approbation',
            [Constants.iso_639_1.german]: 'Genehmigungsstrategie',
            [Constants.iso_639_1.japanese]: '承認戦略',
        },
        [Constants.resourcesType.technologyTraining]: {
            [Constants.iso_639_1.chinese]: '技术培训',
            [Constants.iso_639_1.english]: 'Technology Training',
            [Constants.iso_639_1.french]: 'Formation technique',
            [Constants.iso_639_1.german]: 'Technologietraining',
            [Constants.iso_639_1.japanese]: 'テクノロジートレーニング',
        },
        [Constants.resourcesType.qualitySupportSpec]: {
            [Constants.iso_639_1.chinese]: '质量支持规范',
            [Constants.iso_639_1.english]: 'Quality Support Spec',
            [Constants.iso_639_1.french]: 'Spécification de support qualité',
            [Constants.iso_639_1.german]: 'Qualitätsunterstützungsspezifikation',
            [Constants.iso_639_1.japanese]: '品質サポート仕様',
        },
        [Constants.resourcesType.stsafeACertificates]: {
            [Constants.iso_639_1.chinese]: 'STSAFE-A证书',
            [Constants.iso_639_1.english]: 'STSAFE-A Certificates',
            [Constants.iso_639_1.french]: 'Certificats STSAFE-A',
            [Constants.iso_639_1.german]: 'STSAFE-A-Zertifikate',
            [Constants.iso_639_1.japanese]: 'STSAFE-A証明書',
        },
        [Constants.resourcesType.marketingRequirementsDocument]: {
            [Constants.iso_639_1.chinese]: '市场需求文件',
            [Constants.iso_639_1.english]: 'Marketing Requirements Document',
            [Constants.iso_639_1.french]: 'Document des exigences marketing',
            [Constants.iso_639_1.german]: 'Marketinganforderungsdokument',
            [Constants.iso_639_1.japanese]: 'マーケティング要件文書',
        },
        [Constants.resourcesType.productPortfolio]: {
            [Constants.iso_639_1.chinese]: '产品组合',
            [Constants.iso_639_1.english]: 'Product Portfolio',
            [Constants.iso_639_1.french]: 'Portefeuille de produits',
            [Constants.iso_639_1.german]: 'Produktportfolio',
            [Constants.iso_639_1.japanese]: '製品ポートフォリオ',
        },
        [Constants.resourcesType.qualityProcessSpec]: {
            [Constants.iso_639_1.chinese]: '质量过程规范',
            [Constants.iso_639_1.english]: 'Quality Process Spec',
            [Constants.iso_639_1.french]: 'Spécification de processus qualité',
            [Constants.iso_639_1.german]: 'Qualitätsprozessspezifikation',
            [Constants.iso_639_1.japanese]: '品質プロセス仕様',
        },
    };

    static DidYouMean = {
        DidYouMean: (queryText) => ({
            [Constants.iso_639_1.chinese]: [ '没有找到结果 ', queryText ,'. 你是不是指的是' ],
            [Constants.iso_639_1.english]: [ 'No results found for ', queryText ,'. Did you mean' ],
            [Constants.iso_639_1.french]: [ 'Aucun résultat trouvé pour ', queryText ,'. Vouliez-vous dire' ],
            [Constants.iso_639_1.german]: [ 'Keine Ergebnisse gefunden für ', queryText ,'. Meinten Sie' ],
            [Constants.iso_639_1.japanese]: [ '結果が見つかりません ', queryText ,'. これを意味しましたか' ],
        }),
        ShowingResults: (queryText) => ({
            [Constants.iso_639_1.chinese]: [ '没有找到结果 ', queryText ,'. 显示结果为' ],
            [Constants.iso_639_1.english]: [ 'No results found for ', queryText ,'. Showing results for' ],
            [Constants.iso_639_1.french]: [ 'Aucun résultat trouvé pour ', queryText ,'. Affichage des résultats pour' ],
            [Constants.iso_639_1.german]: [ 'Keine Ergebnisse gefunden für ', queryText ,'. Ergebnisse anzeigen für' ],
            [Constants.iso_639_1.japanese]: [ '結果が見つかりません ', queryText ,'. 次の結果を表示しています' ],
        }),
        DidYouMeanNoResult: (queryText) => ({
            [Constants.iso_639_1.chinese]: [ '没有找到结果 ', queryText ],
            [Constants.iso_639_1.english]: [ 'No results found for ', queryText ],
            [Constants.iso_639_1.french]: [ 'Aucun résultat trouvé pour ', queryText ],
            [Constants.iso_639_1.german]: [ 'Keine Ergebnisse gefunden für ', queryText ],
            [Constants.iso_639_1.japanese]: [ '結果が見つかりません ', queryText ],
        }),
        Result: {
            [Constants.iso_639_1.chinese]: '结果',
            [Constants.iso_639_1.english]: 'result',
            [Constants.iso_639_1.french]: 'résultat',
            [Constants.iso_639_1.german]: 'Ergebnis',
            [Constants.iso_639_1.japanese]: '結果',
        },
        Results: {
            [Constants.iso_639_1.chinese]: '结果',
            [Constants.iso_639_1.english]: 'results',
            [Constants.iso_639_1.french]: 'résultats',
            [Constants.iso_639_1.german]: 'Ergebnisse',
            [Constants.iso_639_1.japanese]: '結果',
        }
    }

    static ErrorMessage = {
        Error: (error) => !error
            ? 'Unknown error'
            : error.status
            ? `${error.statusText} (${error.status}) at ${error.url}`
            : error.toString(),
        ErrorOccured: {
            [Constants.iso_639_1.chinese]: '发生一个错误',
            [Constants.iso_639_1.english]: 'An error occurred',
            [Constants.iso_639_1.french]: 'Une erreur s\'est produite',
            [Constants.iso_639_1.german]: 'Ein Fehler ist aufgetreten',
            [Constants.iso_639_1.japanese]: 'エラーが発生しました',
        }
    }

    static Feedback = {
        Satisfaction: {
            [Constants.iso_639_1.chinese]: '你找到你要找的东西了吗？',
            [Constants.iso_639_1.english]: 'Have you found what you were looking for?',
            [Constants.iso_639_1.french]: 'Avez-vous trouvé ce que vous cherchiez ?',
            [Constants.iso_639_1.german]: 'Haben Sie gefunden, wonach Sie gesucht haben?',
            [Constants.iso_639_1.japanese]: 'お探しのものは見つかりましたか?',
        }
    }

    static ListFilter = {
        NoOptions: {
            [Constants.iso_639_1.chinese]: '无选项',
            [Constants.iso_639_1.english]: 'No options',
            [Constants.iso_639_1.french]: 'Aucune option',
            [Constants.iso_639_1.german]: 'Keine Optionen',
            [Constants.iso_639_1.japanese]: 'オプションなし',
        },
    }

    static LucidworksResultItem = {
        BestResult: {
            [Constants.iso_639_1.chinese]: '最佳结果',
            [Constants.iso_639_1.english]: 'Best Result',
            [Constants.iso_639_1.french]: 'Meilleur Résultat',
            [Constants.iso_639_1.german]: 'Bestes Ergebnis',
            [Constants.iso_639_1.japanese]: 'ベストリザルト',
        }
    }

    static ProductResultItem = {
        Chip: {
            [Constants.iso_639_1.chinese]: '产品',
            [Constants.iso_639_1.english]: 'Product',
            [Constants.iso_639_1.french]: 'Produit',
            [Constants.iso_639_1.german]: 'Produkt',
            [Constants.iso_639_1.japanese]: '製品',
        },
    }

    static SearchBox = {
        EnterSearchTerms: {
            [Constants.iso_639_1.chinese]: '输入搜索词...',
            [Constants.iso_639_1.english]: 'Enter search terms...',
            [Constants.iso_639_1.french]: 'Saisir les termes de recherche...',
            [Constants.iso_639_1.german]: 'Suchbegriffe eingeben...',
            [Constants.iso_639_1.japanese]: '検索条件を入力...',
        },
        Loading: {
            [Constants.iso_639_1.chinese]: '正在加载...',
            [Constants.iso_639_1.english]: 'Loading...',
            [Constants.iso_639_1.french]: 'Chargement...',
            [Constants.iso_639_1.german]: 'Laden...',
            [Constants.iso_639_1.japanese]: '読み込み中...',
        },
        BackArrow: {
            [Constants.iso_639_1.chinese]: '返回',
            [Constants.iso_639_1.english]: 'Back',
            [Constants.iso_639_1.french]: 'Retour',
            [Constants.iso_639_1.german]: 'Zurück',
            [Constants.iso_639_1.japanese]: '戻る',
        },
    }

    static SearchResultsList = {
        BackToStResults: {
            [Constants.iso_639_1.chinese]: '返回到ST结果',
            [Constants.iso_639_1.english]: 'Back to ST results',
            [Constants.iso_639_1.french]: 'Retour aux résultats ST',
            [Constants.iso_639_1.german]: 'Zurück zu den ST-Ergebnissen',
            [Constants.iso_639_1.japanese]: 'STの結果に戻る',
        }
    }

    static SponsoredLinks = {
        YouMayAlsoLike: {
            [Constants.iso_639_1.chinese]: '你可能也会感兴趣...',
            [Constants.iso_639_1.english]: 'You may also like...',
            [Constants.iso_639_1.french]: 'Vous pouvez aussi aimer...',
            [Constants.iso_639_1.german]: 'Das könnte Sie auch interessieren...',
            [Constants.iso_639_1.japanese]: 'こちらもおすすめ...',
        },
    }

    static Stats = {
        ResultsFound: (searchResultCount) => ({
            [Constants.iso_639_1.chinese]: [ '找到 ', searchResultCount, ' 个结果。' ],
            [Constants.iso_639_1.english]: [ 'Showing ', searchResultCount, ' results.' ],
            [Constants.iso_639_1.french]: [ 'Trouvée ', searchResultCount, ' resultats.' ],
            [Constants.iso_639_1.german]: [ searchResultCount, ' Dokumente gefunden.' ],
            [Constants.iso_639_1.japanese]: [ searchResultCount, '件表示' ],
        }),
        ResultsFoundXref: (searchResultCount, queryText) => ({
            [Constants.iso_639_1.chinese]: [ searchResultCount, ' 个 ', queryText, ' 对照检索' ],
            [Constants.iso_639_1.english]: [ searchResultCount, ' cross references for ', queryText ],
            [Constants.iso_639_1.french]: [ searchResultCount, ' références croisées pour ', queryText ],
            [Constants.iso_639_1.german]: [ searchResultCount, ' Querverweise für ', queryText ],
            [Constants.iso_639_1.japanese]: [ searchResultCount, ' ', queryText, ' のクロスリファレンス' ],
        }),
        CrossReference: {
            [Constants.iso_639_1.chinese]: '交叉引用',
            [Constants.iso_639_1.english]: 'X-Reference',
            [Constants.iso_639_1.french]: 'Référence croisée',
            [Constants.iso_639_1.german]: 'Querverweis',
            [Constants.iso_639_1.japanese]: 'クロスリファレンス',
        },
    }

    static TreeListFilter = {
        NextLevel: {
            [Constants.iso_639_1.chinese]: '下一级',
            [Constants.iso_639_1.english]: 'Next level',
            [Constants.iso_639_1.french]: 'Niveau suivant',
            [Constants.iso_639_1.german]: 'Nächste Ebene',
            [Constants.iso_639_1.japanese]: '次のレベル',
        },
        PreviousLevel: {
            [Constants.iso_639_1.chinese]: '上一级',
            [Constants.iso_639_1.english]: 'Previous level',
            [Constants.iso_639_1.french]: 'Niveau précédent',
            [Constants.iso_639_1.german]: 'Vorhergehende Ebene',
            [Constants.iso_639_1.japanese]: '前のレベル',
        },
        RemoveFilter: {
            [Constants.iso_639_1.chinese]: '去除过滤器',
            [Constants.iso_639_1.english]: 'Remove filter',
            [Constants.iso_639_1.french]: 'Retirer le filtre',
            [Constants.iso_639_1.german]: 'Filter löschen',
            [Constants.iso_639_1.japanese]: 'フィルタを削除する',
        },
        ToolsAndSoftware: {
            [Constants.iso_639_1.chinese]: '工具与软件',
            [Constants.iso_639_1.english]: 'Tools And Software',
            [Constants.iso_639_1.french]: 'Outils et logiciels',
            [Constants.iso_639_1.german]: 'Werkzeuge und Software',
            [Constants.iso_639_1.japanese]: 'ツールとソフトウェア',
        },
    }

    static CheckboxListFilter = {
        ShowResults: () => ({
            [Constants.iso_639_1.chinese]: [ '显示结果 ' ],
            [Constants.iso_639_1.english]: [ 'Show results ' ],
            [Constants.iso_639_1.french]: [ 'Afficher les résultats ' ],
            [Constants.iso_639_1.german]: [ 'Ergebnisse anzeigen ' ],
            [Constants.iso_639_1.japanese]: [ '結果を表示する ' ],
        }),
        Documentation: {
            [Constants.iso_639_1.chinese]: '文档',
            [Constants.iso_639_1.english]: 'Documentation',
            [Constants.iso_639_1.french]: 'Documentation',
            [Constants.iso_639_1.german]: 'Dokumentatione',
            [Constants.iso_639_1.japanese]: 'ドキュメンテーション',
        },
        Wiki: {
            [Constants.iso_639_1.chinese]: '维基 ',
            [Constants.iso_639_1.english]: 'Wiki',
            [Constants.iso_639_1.french]: 'Wiki',
            [Constants.iso_639_1.german]: 'Wiki',
            [Constants.iso_639_1.japanese]: 'ウィキ',
        },
        Blog: {
            [Constants.iso_639_1.chinese]: '博客',
            [Constants.iso_639_1.english]: 'Blog',
            [Constants.iso_639_1.french]: 'Blog',
            [Constants.iso_639_1.german]: 'Blog',
            [Constants.iso_639_1.japanese]: 'ブログ',
        },
        News: {
            [Constants.iso_639_1.chinese]: '新闻',
            [Constants.iso_639_1.english]: 'News',
            [Constants.iso_639_1.french]: 'Actualités',
            [Constants.iso_639_1.german]: 'Nachrichten',
            [Constants.iso_639_1.japanese]: 'ニュース',
        },
    }

    static WebResultItem = {
        Chip: {
            [Constants.iso_639_1.chinese]: '网页',
            [Constants.iso_639_1.english]: 'Web page',
            [Constants.iso_639_1.french]: 'Page web',
            [Constants.iso_639_1.german]: 'Webseite',
            [Constants.iso_639_1.japanese]: 'ウェブページ',
        },
    }

    static Pagination = {
        Page: {
            [Constants.iso_639_1.chinese]: '页面',
            [Constants.iso_639_1.english]: 'Page',
            [Constants.iso_639_1.french]: 'Page',
            [Constants.iso_639_1.german]: 'Seite',
            [Constants.iso_639_1.japanese]: 'ページ',
        },
        Of: {
            [Constants.iso_639_1.chinese]: '的',
            [Constants.iso_639_1.english]: 'Of',
            [Constants.iso_639_1.french]: 'De',
            [Constants.iso_639_1.german]: 'Von',
            [Constants.iso_639_1.japanese]: 'の',
        },
    }


    static WikiRepoFileResultItem = {
        Chip: {
            [Constants.iso_639_1.chinese]: '存储库文件',
            [Constants.iso_639_1.english]: 'Repository file',
            [Constants.iso_639_1.french]: 'Fichier de dépôt',
            [Constants.iso_639_1.german]: 'Repository-Datei',
            [Constants.iso_639_1.japanese]: 'リポジトリファイル',
        },
    }

    static WikiWikiPageResultItem = {
        Chip: {
            [Constants.iso_639_1.chinese]: '维基页面',
            [Constants.iso_639_1.english]: 'Wiki page',
            [Constants.iso_639_1.french]: 'Page wiki',
            [Constants.iso_639_1.german]: 'Wikiseite',
            [Constants.iso_639_1.japanese]: 'ウィキのページ',
        },
    }

    static XrefNotice = {
        Heading: {
            [Constants.iso_639_1.chinese]: '交叉引用',
            [Constants.iso_639_1.english]: 'Cross References',
            [Constants.iso_639_1.french]: 'Références Croisées',
            [Constants.iso_639_1.german]: 'Querverweise',
            [Constants.iso_639_1.japanese]: 'クロスリファレンス',
        },
        Description: {
            [Constants.iso_639_1.chinese]: 'Xref 是交叉引用的简称，是相关产品之间的连接，可帮助您在搜索时发现更多选项。当您搜索特定商品时，我们的搜索引擎会使用 Xref 根据各种标准查找类似或相关的产品。',
            [Constants.iso_639_1.english]: 'Xref, short for cross-references, are connections between related products that helps you discover additional options while searching. When you search for a specific item, our search engine uses Xref to find similar or relevant products based on various criteria.',
            [Constants.iso_639_1.french]: 'Les Xref, abréviation de cross-references, sont des connexions entre des produits apparentés qui vous aident à découvrir des options supplémentaires lors de vos recherches. Lorsque vous recherchez un article spécifique, notre moteur de recherche utilise les références croisées pour trouver des produits similaires ou pertinents en fonction de divers critères.',
            [Constants.iso_639_1.german]: 'Xref, kurz für Querverweise, sind Verbindungen zwischen verwandten Produkten, die Ihnen bei der Suche nach zusätzlichen Optionen helfen. Wenn Sie nach einem bestimmten Artikel suchen, verwendet unsere Suchmaschine Xref, um ähnliche oder relevante Produkte auf der Grundlage verschiedener Kriterien zu finden.',
            [Constants.iso_639_1.japanese]: 'Xref (クロスレファレンス) とは、クロスリファレンスの略で、関連商品間の接続のことです。お客様が特定の商品を検索する際、弊社の検索エンジンはXrefを使用し、様々な条件に基づいて類似商品や関連商品を検索します。',
        },
        LinkText: (searchResultCount) => ({
            [Constants.iso_639_1.chinese]: [ '显示 ', searchResultCount, ' 个交叉引用' ],
            [Constants.iso_639_1.english]: [ 'show ', searchResultCount, ' cross references' ],
            [Constants.iso_639_1.french]: [ 'montre', searchResultCount, ' références croisées' ],
            [Constants.iso_639_1.german]: [ 'zeige ', searchResultCount, ' Querverweise an' ],
            [Constants.iso_639_1.japanese]: [ searchResultCount, 'のクロスリファレンスを表示' ],
        }),
        LinkTextSTablet: (searchResultCount) => ({
            [Constants.iso_639_1.chinese]: [ searchResultCount, ' 个交叉引用' ],
            [Constants.iso_639_1.english]: [ searchResultCount, ' cross references' ],
            [Constants.iso_639_1.french]: [ searchResultCount, ' références croisées' ],
            [Constants.iso_639_1.german]: [ searchResultCount, ' Querverweise an' ],
            [Constants.iso_639_1.japanese]: [ searchResultCount, 'のクロスリファレンスを表示' ],
        })
    }

    static XrefResultTable = {
        Chip: {
            [Constants.iso_639_1.chinese]: '参照',
            [Constants.iso_639_1.english]: 'Cross Reference',
            [Constants.iso_639_1.french]: 'Référence Croisée',
            [Constants.iso_639_1.german]: 'Querverweis',
            [Constants.iso_639_1.japanese]: '相互参照',
        },
        HeaderName: {
            Difference: {
                [Constants.iso_639_1.chinese]: '差异',
                [Constants.iso_639_1.english]: 'Difference',
                [Constants.iso_639_1.french]: 'Différence',
                [Constants.iso_639_1.german]: 'Unterschied',
                [Constants.iso_639_1.japanese]: '違い',
            },
            ProductCategory: {
                [Constants.iso_639_1.chinese]: '产品类别',
                [Constants.iso_639_1.english]: 'Product Category',
                [Constants.iso_639_1.french]: 'Catégorie de Produit',
                [Constants.iso_639_1.german]: 'Produktkategorie',
                [Constants.iso_639_1.japanese]: '製品カテゴリー',
            },
            Replacement: {
                [Constants.iso_639_1.chinese]: '更换',
                [Constants.iso_639_1.english]: 'Replacement',
                [Constants.iso_639_1.french]: 'Remplacement',
                [Constants.iso_639_1.german]: 'Ersatz',
                [Constants.iso_639_1.japanese]: '交換',
            },
            STPartNumber: {
                [Constants.iso_639_1.chinese]: 'ST 部件编号',
                [Constants.iso_639_1.english]: 'ST Part Number',
                [Constants.iso_639_1.french]: 'ST Numéro de Pièce',
                [Constants.iso_639_1.german]: 'ST-Teilenummer',
                [Constants.iso_639_1.japanese]: 'ST品番',
            },
            VendorName: {
                [Constants.iso_639_1.chinese]: '供应商名称',
                [Constants.iso_639_1.english]: 'Vendor Name',
                [Constants.iso_639_1.french]: 'Nom du Vendeur',
                [Constants.iso_639_1.german]: 'Name des Anbieters',
                [Constants.iso_639_1.japanese]: 'ベンダー名',
            },
            VendorPartNumber: {
                [Constants.iso_639_1.chinese]: '供应商零件编号',
                [Constants.iso_639_1.english]: 'Vendor Part Number',
                [Constants.iso_639_1.french]: 'Numéro de Pièce du Fournisseur',
                [Constants.iso_639_1.german]: 'Lieferanten-Teilenummer',
                [Constants.iso_639_1.japanese]: 'ベンダー品番',
            },
        }
    }
}

export default Labels