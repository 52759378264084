//
// React.
//
import PropTypes from 'prop-types'
//
// Search.
//
import Constants from '../config/Constants'
import Search from './Search'

class ResultItem extends Search {
    //
    // Construct a new instance.
    //
    // eslint-disable-next-line no-useless-constructor
    constructor(props) {
        super(props)
    }
    //
    // Defines the style and structure of bestBet in the results
    //
    firstResultBoxProperties = {
        border: 'solid 1px #dbdee2',
        backgroundColor: '#ffd200',
        borderRadius: '3px',
        color: '#03234b',
        display: 'inline-flex',
        fontSize: '13px',
        fontWeight: 'bold',
        pb: 0.25,
        pl: 1,
        pr: 1,
        pt: 0.25,
        mb: 1,
        mr: 1,
        textTransform: 'uppercase',
    }
    //
    // Standard style and structure box for the results
    //
    boxProperties = {
        border: 1,
        borderColor: 'grey.700',
        borderRadius: '4px',
        color: '#525a63',
        display: 'inline-flex',
        fontSize: '13px',
        pb: 0.25,
        pl: 1,
        pr: 1,
        pt: 0.25,
        mb: 1,
        textTransform: 'uppercase',
    }
    //
    // Sends the signal when the user clicks on a specific result
    //
    sendResultClickedSignal = (event, position) => {
        //
        // Retrieves the configured filters from the component props, or defaults to an empty object
        //
        const configuredFilters = this.props?.config?.filters || this.props?.configFilters || {}
        //
        // Filters the search parameters to keep only those that exist in the configured filters
        // Further filters the parameters to keep only those associated with the currently active source
        //
        var filterParameters = Object.keys(this.props?.searchParameters).filter(parameter => Object.keys(configuredFilters).includes(parameter))
        filterParameters = filterParameters.filter(filterName => (configuredFilters[filterName].display?.sources || []).includes(this.getActiveSource()))
        //
        // Extracts only the filters that have valid (non-empty) values
        //
        const activeFilters = filterParameters.filter(filterName => {
            const filterValues = this.props?.searchParameters[filterName];
            return filterValues && filterValues.length > 0 && filterValues.some(value => value !== "");
        });
        //
        // Sends the signal with result details and active filters (if any)
        //
        this.sendSignal(Constants.signal.resultClicked, this.props?.searchParameters, [
            {
                name: 'href',
                values: [ event.target.href ],
            },
            {
                name: 'position',
                values: [ position || this.props?.position ],
            },
            {
                name: 'filters',
                values: activeFilters.map(activeFilterName => ({
                    name: activeFilterName,
                    values: this.props?.searchParameters[activeFilterName],
                }))
            },
            ...(this.getActiveSource() === Constants.resultSource.lucidworks
            ? [{
                name: 'page',
                values: [this.props?.searchParameters[Constants.parameter.pageLucidworks]],
            }]
            : [])
        ])
    }
}

ResultItem.propTypes = {
    searchParameters: PropTypes.object.isRequired,
    position: PropTypes.number.isRequired,
}

export default ResultItem