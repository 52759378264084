//
// React.
//
import PropTypes from 'prop-types'
import React from 'react'
//
// Search.
//
import Constants from '../config/Constants'
import Labels from '../config/Labels'
import Util from '../services/Util'
//
// Base class holding logic for using the search back-end. UI components
// should extend this class to have access this functionality.
//
class Search extends React.Component {
    //
    // Construct a new instance.
    //
    constructor(props) {
        super(props)
        //
        // Initialize a history stack to store the search parameter objects.
        //
        this.historyStack = []
        //
        // Store filterNames generated by a repeated search - checkboxFilters.
        //
        this.filterNamesList = []
        //
        // Track a search with a selected suggestion
        //
        this.selectedSuggestion = false
    }
    //
    // Build a location URI using the given search parameters.
    //
    buildLocation = (searchParameters) => {
        const requestParameters = Object.entries(searchParameters)
            .map(([key, value]) => (Util.isValue(value)) ? `${key}=${encodeURIComponent(JSON.stringify(value))}` : '')
            .filter(Boolean)
            .join('&')
        return `${window.location.origin}?${requestParameters}`
    }
    //
    // Get the name of the active source (to select the active tab).
    //
    getActiveSource = () => {
        return this.props.searchParameters[Constants.parameter.activeSource] || Constants.defaultParameterValue[Constants.parameter.activeSource]
    }
    //
    // Get the label for the current language from a multi-language string.
    //
    getLabel = (label) => {
        return Util.getLabel(label, this.getLanguage())
    }
    //
    // Get the current UI language.
    //
    getLanguage = () => {
        return (this.props.searchParameters && this.props.searchParameters[Constants.parameter.language]) || Constants.defaultParameterValue[Constants.parameter.language]
    }
    //
    // Get the name of the page parameter depending on the active source.
    //
    getPageParameter = (source) => {
        return Util.getPageParameter(source || this.getActiveSource())
    }
    //
    // Get the sources contained in the search result.
    //
    getSources = () => {
        return this.props.searchResult ? Object.keys(this.props.searchResult) : []
    }
    //
    // Logic for the back arrow.
    //
    handleBackClick = () => {
        window.location.href = 'https://www.st.com/'
    };
    //
    // Push the search parameters to the browser history.
    //
    pushHistory = (searchParameters) => {
        window.history.pushState({}, '', this.buildLocation(searchParameters))
        //
        // Check the LOCAL history stack for the new search parameters.
        //
        const alreadyExists = this.historyStack.some((params) => {
            return (JSON.stringify(params) === JSON.stringify(searchParameters))
        })
        //
        // If hte LOCAL history stack doesn't contain the search parameters
        // yet, add them.
        //
        if (!alreadyExists) {
            this.historyStack = this.historyStack.filter((params) => {
                return JSON.stringify(params) !== JSON.stringify(this.props.searchParameters)
            })
            this.pushToHistoryStack(searchParameters)
        }
    }
    //
    // Push the search parameters to the HistoryStack.
    //
    pushToHistoryStack = (searchParameters) => {
        this.historyStack.push(searchParameters);
    }
    //
    // Get the filter configuration for the given name.
    //
    getConfiguredFilterInSearch = (filterName) => {
        return this.getConfiguredFiltersInSearch ()[filterName] || {}
    }
    //
    // Get the configured filters.
    //
    getConfiguredFiltersInSearch  = () => {
        return this.props.config?.filters || {}
    }
    //
    // Check if the filter with the given name has the specified filter type.
    // Returns true if the filter type matches, false otherwise.
    //
    isFilterType = (filterName, filterType) => {
        return (this.getConfiguredFilterInSearch (filterName).type === filterType)
    }
    //
    // Check if the filter with the given name is of type tree list.
    // Returns true if it is, false otherwise.
    //
    isTreeListFilter = (filterName) => {
        return this.isFilterType(filterName, Constants.filterType.treelist)
    }
    //
    // Check if the filter with the given name is of type checkbox list.
    // Returns true if it is, false otherwise.
    //
    isCheckBoxListFilter = (filterName) => {
        return this.isFilterType(filterName, Constants.filterType.checkboxlist)
    }
    //
    // Set digitalData for tracking.
    //
    setDigitalData = (searchParameters, lucidworksResult, searchResultStatus, fromLocation, fromDidyoumean) => {
        //
        // Preparing filters (Navigation filters)
        //
        const navigationFilters = Object.keys(searchParameters)
            .filter(filterName => this.isTreeListFilter(filterName))
            .map(key => searchParameters[key]);
        //
        // Preparing filters (Checkbox filters)
        //
        const CheckboxFilters = Object.keys(searchParameters)
            .filter(filterName => this.isCheckBoxListFilter(filterName))
            .map(key => searchParameters[key]);
        //
        // Stores if tracking with filters is requested
        //
        const applyWithFilters = (navigationFilters.length > 0 && navigationFilters[0]?.length !== 0) || (CheckboxFilters.length > 0 && CheckboxFilters[0]?.length !== 0)
        //
        // Check if tracking is requested when the page loads or if the URL has filters in the parameters.
        // This logic avoids duplicate tracking and tracking management when filters are applied from the URL
        // Based on the requirements requested for tracking.
        //
        if (!applyWithFilters || fromLocation){
            const data = window.digitalData.search;
            if (fromLocation && data && (data.searchEvent === 'searchCheckboxFilter' || data.searchEvent === 'searchNavigationFilter')) {
                return;
            }
            if (fromLocation && !applyWithFilters){
                return;
            }
            if (!(
                data &&
                data.searchEvent === 'searchDone' &&
                data.searchResultNumber === (lucidworksResult.result?.total || 0) &&
                data.searchResultStatus === searchResultStatus &&
                data.searchType === (searchParameters.queryText || '')
            )) {
                window.digitalData.search = {
                    searchEvent: 'searchDone',
                    searchResultNumber: lucidworksResult.result?.total || 0,
                    searchResultStatus: searchResultStatus,
                    searchType: fromDidyoumean ? (searchParameters.didYouMean || '') : (searchParameters.queryText || ''),
                    searchSourcePage: '',
                }
                //
                // Waits for the Adobe Analytics (_satellite) script to load.
                // Retries up to 1000 times with a 100ms interval before stopping.
                // Maximum wait time: 1 minute and 40 seconds.
                //
                let attempts = 0;
                const maxAttempts = 1000;
                (function waitForAAScript() {
                    if (!window._satellite && attempts++ < maxAttempts)
                        //
                        // If _satellite is not available, retry after 100ms
                        //
                        setTimeout(waitForAAScript, 100);
                    else if (window._satellite) {
                        //
                        // If _satellite is available, check the track event
                        //
                        (function checkTrackEvent() {
                            if (typeof window.trackedSearchDoneEvent === "undefined") {
                                window._satellite.track("searchDone");
                                setTimeout(checkTrackEvent, 100);
                            }
                        })();
                    }
                })();
            }
        }
    };
    //
    // Run a search with the given parameters.
    //
    search = (searchParameters, clearAllFilters, applycheckboxFilters, checkboxFiltersToApply) => {
        //
        // Clear previousFacets when no filters to get an original loading page.
        //
        if (clearAllFilters) {
            const filters = this.getConfiguredFiltersInSearch()
            var filterParameters = Object.keys(searchParameters).filter(parameter => Object.keys(filters).includes(parameter))
            var activeFilters = filterParameters.filter(filterName => (searchParameters[filterName] && searchParameters[filterName].length > 0)) || []
            if((searchParameters[Constants.parameter.queryText] !== this.props.searchParameters[Constants.parameter.queryText])
            || (activeFilters && activeFilters.length === 0)){
                this.props.setpreviousFacets({})
            }
        }
        //
        // If there is an active "did you mean" query, replace the query text with
        // it and delete it (activating the results from the previous search repetition).
        //
        const isDidYouMean = (searchParameters.hasOwnProperty(Constants.parameter.didYouMean)) && (searchParameters[Constants.parameter.didYouMean] !== Constants.didYouMean.noSuggestionsAvailable)
        if (isDidYouMean) {
            searchParameters[Constants.parameter.queryText] = searchParameters[Constants.parameter.didYouMean]
            delete searchParameters[Constants.parameter.didYouMean]
        }
        //
        // Check the page parameter if this is a pagination event.
        //
        const pageParameter = this.getPageParameter(this.getActiveSource())
        const currentPage = this.props.searchParameters[pageParameter] || 1
        const nextPage = searchParameters[pageParameter] || 1
        const isPagination = (currentPage !== nextPage)
        //
        // If the provided query text does not match the previous query text
        // AND it is NOT a pagination event
        // AND it is not a "did you mean" search (which will have changed the query text),
        // a new search is triggered and the filter result will need an update.
        //
        const isNewSearch = !isDidYouMean && !isPagination && (this.props.searchParameters[Constants.parameter.queryText] !== searchParameters[Constants.parameter.queryText])
        //
        // If keeping the LAST selection only is active, clear all but the
        // last set filter.
        //
        if (this.props.config?.searchResults?.keepLastSelectionOnly) {
            //
            // Check if there are any new filters.
            //
            const newParameters = Util.symmetricalDifference(Object.keys(this.props.searchParameters), Object.keys(searchParameters))
            if (newParameters.length > 0) {
                //
                // Determine the existing filters (removing other
                // parameters like pagination, language, ...).
                //
                const existingParameters = Util.intersection(Object.keys(this.props.searchParameters), Object.keys(searchParameters))
                const filterNames = Object.keys(this.props.config?.filters || {})
                const lastSelectedFilters = Util.intersection(existingParameters, filterNames)
                //
                // If there is an existing filter, remove it (assuming
                // that there always is only one active filter).
                //
                if (lastSelectedFilters.length > 0) {
                    delete searchParameters[lastSelectedFilters[0]]
                }
            }
        }
        //
        // Update the search parameters and push them to the browser history.
        //
        this.props.setSearchParameters(searchParameters)
        this.pushHistory(searchParameters)
        //
        // Clear the search results and scroll to the top of the window.
        //
        this.props.setSearchResult({})
        window.scrollTo(0, 0)
        //
        // Send the query to the middleware and update search results based on the response.
        //
        Util.submitPostRequest(
            `${Constants.apiVersion}/search`, searchParameters
        ).then(searchResult => {
            if (applycheckboxFilters) {
                //
                // Check the results from each result source for checkbox list filters (their
                // value has been removed previously so that the full list will be returned).
                // Cache the list entries so that the full list can be displayed (selected
                // AND non-selected values).
                //
                const customSearchParameters = JSON.parse(JSON.stringify(searchParameters));
                Object.values(searchResult).forEach(resultSource => {
                    const facets = resultSource.result?.facets || {}
                    Object.keys(checkboxFiltersToApply).forEach(filterName => {
                        //
                        // If the facet exists, cache its list entries.
                        //
                        if (facets[filterName]) {
                            this.props.setCachedFilters({
                                ...this.props.cachedFilters,
                                [filterName]: {
                                    searchParameters: customSearchParameters,
                                    filterValues: facets[filterName]
                                }
                            })
                            this.filterNamesList.push(filterName);
                        }
                        //
                        // Restore the search parameters so the the search result list is
                        // properly filtered.
                        //
                        customSearchParameters[filterName] = checkboxFiltersToApply[filterName]
                    })
                })
                //
                // Re-search to apply the missing checkboxFilters
                //
                this.search(customSearchParameters)
            } else {
                if (this.filterNamesList.length > 0) {
                    const facets = searchResult[Constants.resultSource.lucidworks].result?.facets || {}
                    this.filterNamesList.forEach(filterName => {
                        //
                        // Update the search parameters and push them to the browser history.
                        //
                        const updatedSearchParameters = {
                            ...searchParameters,
                            [filterName]: facets[filterName]?.map(item => item.value) || []
                        };
                        this.props.setSearchParameters(updatedSearchParameters)
                        this.pushHistory(updatedSearchParameters)
                    })
                    //
                    // Clean the filterNamesList
                    //
                    this.filterNamesList = [];
                }
                this.props.setSearchResult(searchResult)
                //
                // If the query text has changed or preserving filters is disabled,
                // store the search results also in the filter results used in the
                // filter panel.
                //
                if (isNewSearch || !this.props.config?.searchResults?.preserveFilters) {
                    this.props.setFilterResult(searchResult)
                }
                //
                // If there is a response from Lucidworks AND the result count is 0, trigger
                // "did you mean".
                //
                const lucidworksResult = searchResult[Constants.resultSource.lucidworks] || {}
                if(lucidworksResult.result && (lucidworksResult.result.total > 0)){
                    this.props.setpreviousFacets(searchResult[Constants.resultSource.lucidworks].result?.facets || {})
                }
                if (lucidworksResult.result && (lucidworksResult.result.total === 0)) {
                    this.setDigitalData(searchParameters, lucidworksResult, 'no match')
                    if (lucidworksResult.result.didYouMean) {
                        //
                        // Update the search parameters and...
                        //
                        const updatedSearchParameters = {
                            ...searchParameters,
                            [Constants.parameter.didYouMean]: lucidworksResult.result.didYouMean
                        }
                        this.props.setSearchParameters(updatedSearchParameters)
                        //
                        // ...use them to repeat the search with "did you mean" enabled.
                        //
                        this.sendSignal(Constants.signal.didYouMeanTriggered, updatedSearchParameters, [ {
                            [Constants.parameter.didYouMean]: updatedSearchParameters[Constants.parameter.didYouMean]
                        }])
                        Util.submitPostRequest(`${Constants.apiVersion}/search`, updatedSearchParameters).then(dymSearchResult => {
                            this.props.setSearchResult(dymSearchResult)
                            this.props.setpreviousFacets(dymSearchResult[Constants.resultSource.lucidworks].result?.facets || {})
                            const lucidworksResultDym = dymSearchResult[Constants.resultSource.lucidworks] || {}
                            //
                            // Update filter result only if "did you mean" produced results.
                            //
                            if (lucidworksResultDym.result && (lucidworksResultDym.result.total > 0)) {
                                this.props.setFilterResult(dymSearchResult)
                                this.setDigitalData(updatedSearchParameters, lucidworksResultDym, 'success', undefined, true)
                            } else  {
                                //
                                // Logic for no results founds
                                //
                                const updatedSearchParameters = {
                                    ...searchParameters,
                                    [Constants.parameter.didYouMean]: Constants.didYouMean.noSuggestionsAvailable
                                }
                                this.props.setSearchParameters(updatedSearchParameters)
                                this.sendSignal(Constants.signal.didYouMeanTriggered, updatedSearchParameters, [ {
                                    [Constants.parameter.didYouMean]: updatedSearchParameters[Constants.parameter.didYouMean]
                                }])
                                this.setDigitalData(updatedSearchParameters, lucidworksResultDym, 'no match', undefined, true)
                            }
                        }).catch(error => {
                            this.props.setSearchResult({
                                error: Labels.ErrorMessage.Error(error)
                            })
                            this.props.setpreviousFacets({})
                        })
                    } else {
                        //
                        // Logic for no results founds
                        //
                        const updatedSearchParameters = {
                            ...searchParameters,
                            [Constants.parameter.didYouMean]: Constants.didYouMean.noSuggestionsAvailable
                        }
                        this.props.setSearchParameters(updatedSearchParameters)
                        this.sendSignal(Constants.signal.didYouMeanTriggered, updatedSearchParameters, [ {
                            [Constants.parameter.didYouMean]: updatedSearchParameters[Constants.parameter.didYouMean]
                        }])
                    }
                } else if (clearAllFilters) {
                    //
                    // Logic for clearAllFilters
                    //
                    window.digitalData.search = {
                        searchEvent: 'searchClearFilter',
                        searchNavigationFilters: 'no filter',
                        searchNavigationFiltersDepth: 'no filter',
                        searchCheckboxFilters: 'no filter',
                    }
                    if (window._satellite) {
                        window._satellite.track('searchClearFilters')
                    }
                } else if (lucidworksResult.result === undefined) {
                    //
                    // Logic for no results founds
                    //
                    const updatedSearchParameters = {
                        ...searchParameters,
                        [Constants.parameter.didYouMean]: Constants.didYouMean.noSuggestionsAvailable
                    }
                    this.props.setSearchParameters(updatedSearchParameters)
                    this.setDigitalData(searchParameters, lucidworksResult, 'no match')
                } else {
                    this.setDigitalData(searchParameters, lucidworksResult, this.selectedSuggestion ? 'suggested': 'success')
                }
            }
        }).catch(error => {
            this.props.setSearchResult({
                error: Labels.ErrorMessage.Error(error)
            })
            this.props.setpreviousFacets({})
        })
    }
    //
    // Send signals.
    //
    sendSignal = (signal, searchParameters, data) => {
        //
        // Send the given signals to the middleware.
        //
        Util.submitPostRequest(
            `${Constants.apiVersion}/signals`, {
                signal: signal,
                searchParameters: searchParameters,
                data: data || [],
            }
        ).then(response => {
            if (response?.status !== 'ok') {
                console.error(`Unexpected signals response from middleware: ${JSON.stringify(response)}`)
            }
        }).catch(error => {
            console.error(`Signals error: ${error}`)
        })
    }
    //
    // Start a new search with the given query text. All filters and
    // pagination will be reset.
    //
    startNewSearch = (queryText, signal, selectedSuggestion) => {
        //
        // Clear the trackedSearchDoneEvent to be able to track a new search
        //
        if (window.trackedSearchDoneEvent) {
            window.trackedSearchDoneEvent = undefined;
        }
        //
        // True: Track a search with a selected suggestion
        //
        this.selectedSuggestion = selectedSuggestion
        //
        // Reset the isFirstClick and isFirstClickXRef values to tack again in a new search
        // Clear the window.digitalData.search to make sure that a startNewSearch method will track with "searchDone"
        //
        if(this.props.setIsFirstClick && this.props.setIsFirstClickXRef) {
            window.digitalData.search = {}
            this.props.setIsFirstClick("yes")
            this.props.setIsFirstClickXRef("yes")
        }
        //
        // Reset some values to default and replace the query text with
        // the given value.
        //
        const updatedSearchParameters = {
            [Constants.parameter.activeSource]: Constants.defaultParameterValue[Constants.parameter.activeSource],
            [Constants.parameter.language]: this.props.searchParameters[Constants.parameter.language],
            [Constants.parameter.queryText]: queryText || '',
        }
        //
        // ...reset the pagination for all result sources...
        //
        const resultSources = this.props.config?.sources?.values || [ Constants.defaultParameterValue[Constants.parameter.activeSource] ]
        resultSources.forEach(resultSource => {
            updatedSearchParameters[this.getPageParameter(resultSource)] = 1
        })
        //
        // ...clear the caches for (CheckboxList) filters...
        //
        this.props.setCachedFilters({})
        //
        // ...and submit the search request with the updated query text.
        //
        this.search(updatedSearchParameters)
        this.sendSignal(signal || Constants.signal.searchSubmitted, updatedSearchParameters, [
            {
                name: 'queryText',
                values: [ queryText ],
            }
        ])
    }
}

Search.propTypes = {
    config: PropTypes.object.isRequired,
    filterResult: PropTypes.object.isRequired,
    searchParameters: PropTypes.object.isRequired,
    searchResult: PropTypes.object.isRequired,
    setCachedFilters: PropTypes.func.isRequired,
    setFilterResult: PropTypes.func.isRequired,
    setSearchParameters: PropTypes.func.isRequired,
    setSearchResult: PropTypes.func.isRequired,
    setpreviousFacets: PropTypes.func.isRequired,
}

export default Search